import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useLocation, useNavigate as useNavigateDom} from 'react-router-dom';

import queryString from 'query-string';
import {removeEmpty} from '../utils/DataUtil';

/**
 * Hook very similar to react-router-dom useNavigate hook except the search parameters can be preserved and updated.
 * @return {(function(*, *, boolean=, {}=): void)|*}
 */
export default function useNavigate() {
   const [searchParams] = useSearchParams();
   const location = useLocation();
   const navigate = useNavigateDom();

   return useCallback(
      (to, options, isPreserveSearch = true, updateSearch = {}) => {
         let useTo;

         if (isPreserveSearch && (location.search || updateSearch)) {
            const searchParamsObject = queryString.parse(searchParams.toString());
            const useParamsObject = removeEmpty({...searchParamsObject, ...updateSearch, ...(to?.search || {})});
            const search = queryString.stringify(useParamsObject);

            useTo = typeof to === 'string' ? {pathname: to, search} : {pathname: to.pathname, search};
         } else {
            useTo = to;
         }
         navigate(useTo, options);
      },
      [location.search, navigate],
   );
}
