import {Grid} from '@mui/material';
import {TextField as TextFieldMUI} from '@mui/material';
import {useMemo} from 'react';
import React from 'react';
import {useIntl} from 'react-intl';
import {formatMessage} from '../fhg/utils/Utils';

/**
 * The TextField with preset formats.
 */
export const TextFieldFHG = React.forwardRef(function TextFieldFHG(
   {
      className,
      name,
      labelKey,
      placeholderKey,
      placeholder,
      helpKey,
      helperText,
      defaultValue,
      value,
      margin,
      InputLabelProps,
      InputProps,
      label,
      fullWidth = true,
      isFormattedNumber,
      variant = 'outlined',
      classes,
      editData,
      labelTemplate,
      layout,
      ...textFieldProps
   },
   ref
) {
   const intl = useIntl();
   const useLabelKey = useMemo(() => labelKey || labelTemplate?.format({name}), [name, labelTemplate, labelKey]);
   const currentLabel = label || (useLabelKey && formatMessage(intl, useLabelKey)) || undefined;
   const currentPlaceholder = placeholder || (placeholderKey && formatMessage(intl, placeholderKey)) || undefined;
   const helpText = helperText || (helpKey && formatMessage(intl, helpKey)) || undefined;

   const useInputProps = {
      ...InputProps,
   };

   const useValue = value !== undefined ? value : defaultValue;

   const textComponent = (
      <TextFieldMUI
         key={name + ' ' + defaultValue}
         ref={ref}
         name={name}
         {...textFieldProps}
         value={useValue}
         label={currentLabel}
         placeholder={currentPlaceholder}
         helperText={helpText}
         InputLabelProps={InputLabelProps}
         InputProps={useInputProps}
         className={className}
         variant={variant}
         size='small'
         margin={margin || 'none'}
         fullWidth={fullWidth}
      />
   );
   if (layout) {
      return (
         <Grid item {...layout}>
            {textComponent}
         </Grid>
      );
   } else {
      return textComponent;
   }
});

export default TextFieldFHG;
