import {Stack} from '@mui/material';
import {CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(
   () => ({
      progressStyle: {
         zIndex: 5000,
      },
   }),
   {name: 'loadingStyles'}
);

/**
 * Component to show the error messages and loading spinner.
 *
 * Note:
 *    Message is the default property in values.
 */
function LoadingValue({
   isLoading = true,
   showChildrenOnLoad = false,
   size: sizeProp = 'small',
   children,
   classes: classesProp = {},
   thickness,
   isAboveChildren = false,
   ...progressProps
}) {
   let theSize, theThickness;

   const classes = {...useStyles(), ...classesProp};

   if (sizeProp === 'small') {
      theSize = 15;
      theThickness = 2.5;
   } else if (sizeProp === 'medium') {
      theSize = 25;
      theThickness = 3;
   } else {
      theSize = sizeProp;
      theThickness = thickness;
   }

   if (!showChildrenOnLoad) {
      if (isLoading) {
         return (
            <Stack height='25%' width='100%' display={'flex'} justifyContent={'center'} alignItems={'center'}>
               <CircularProgress
                  className={classes.progressStyle}
                  size={theSize}
                  thickness={theThickness}
                  {...progressProps}
               />
            </Stack>
         );
      } else {
         return children;
      }
   } else {
      return (
         <>
            {!isAboveChildren && children}
            {isLoading && (
               <CircularProgress
                  className={classes.progressStyle}
                  size={theSize}
                  thickness={theThickness}
                  {...progressProps}
               />
            )}
            {isAboveChildren && children}
         </>
      );
   }
}

LoadingValue.propTypes = {
   isLoading: PropTypes.bool, // Indicates if the data is still loading.
};

export default LoadingValue;
