import {Stack} from '@mui/material';
import {Skeleton} from '@mui/material';
import {makeStyles} from '@mui/styles';
import useTheme from '@mui/styles/useTheme';
import find from 'lodash/find';
import moment from 'moment';
import {useCallback} from 'react';
import {useMemo} from 'react';
import React from 'react';
import {useMatch} from 'react-router-dom';
import {DATE_FORMAT_KEYBOARD} from '../../../Constants';
import {SETTLEMENT_COMMODITY_MONTHLY_FULL_PATH} from '../../../Constants';
import {COMMODITY_ITEM_PATH} from '../../../Constants';
import {DATE_DB_FORMAT} from '../../../Constants';
import {LIST_WIDTH_DEFAULT} from '../../../Constants';
import {SETTLEMENT_PRICE_HISTORY_ALL_WHERE_INITIALIZE_QUERY} from '../../../data/QueriesGL';
import {COMMODITY_NAME_ALL_QUERY} from '../../../data/QueriesGL';
import DatePickerFHG from '../../../fhg/components/edit/DatePickerFHG';
import useEditData from '../../../fhg/components/edit/useEditData';
import LoadingValue from '../../../fhg/components/LoadingValue';
import TypographyFHG from '../../../fhg/components/Typography';
import useQueryFHG from '../../../fhg/hooks/data/useQueryFHG';
import {useCustomSearchParams} from '../../../fhg/hooks/useCustomSearchParams';
import ListSearchable from '../../../fhg/pages/ListSearchable';
import {formatCurrency} from '../../../fhg/utils/DataUtil';
import {removeEmpty} from '../../../fhg/utils/DataUtil';

const useStyles = makeStyles(
   (theme) => ({
      titleStyle: {
         paddingRight: theme.spacing(2),
         fontWeight: '600 !important',
      },
      progressStyle: {
         zIndex: 5000,
         marginTop: theme.spacing(2),
      },
   }),
   {name: 'SettlementCommodityListStyles'},
);

/**
 * Component to display skeleton when loading.
 * @return {JSX.Element}
 * @constructor
 */
function ListSkeleton() {
   const theme = useTheme();
   const classes = useStyles();

   return (
      <Stack direction={'column'} width={300} sx={{mt: 3, ml: 3, mr: 4}} position={'relative'}>
         <TypographyFHG
            className={classes.titleStyle}
            color={theme.palette.text.secondary}
            variant='h5'
            id='commodities.title'
         />
         <Stack direction={'column'} position={'absolute'} sx={{mt: 5}}>
            <Skeleton variant='rectangular' animation='wave' sx={{mt: 2, mb: 2, mr: 3}} width={300} height={90} />
            <Skeleton variant='rectangular' animation='wave' sx={{mb: 2}} width={300} height={45} />
            <Skeleton variant='rectangular' animation='wave' sx={{mb: 2}} width={300} height={45} />
            <Skeleton variant='rectangular' animation='wave' sx={{mb: 2}} width={300} height={45} />
         </Stack>
      </Stack>
   );
}

/**
 * Component to display the monthly pricing commodity list.
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function SettlementCommodityList() {
   const classes = useStyles();
   const resolvedPath = useMatch(SETTLEMENT_COMMODITY_MONTHLY_FULL_PATH);
   const commodityId = resolvedPath?.params?.[COMMODITY_ITEM_PATH];
   const [searchParams, setSearchParams] = useCustomSearchParams();
   const {date, search} = searchParams;

   // The initial variables for the SETTLEMENT_PRICE_HISTORY_ALL_WHERE_QUERY.
   const initialVariables = useMemo(() => {
      const initialDate = date ? moment(date) : moment().endOf('month');

      return {
         ...removeEmpty(searchParams),
         searchDate: moment(initialDate).format(DATE_DB_FORMAT),
      };
   }, [date, searchParams]);

   const [commodityData, {loading: loadingCommoditiyNames}] = useQueryFHG(
      COMMODITY_NAME_ALL_QUERY,
      {errorPolicy: 'all'},
      'commodity.type',
      false,
   );
   const [priceData, {loading: loadingPriceHistory}] = useQueryFHG(
      SETTLEMENT_PRICE_HISTORY_ALL_WHERE_INITIALIZE_QUERY,
      {variables: {...initialVariables}, errorPolicy: 'all'},
      'price.type',
      false,
   );
   const commodities = useMemo(() => commodityData?.commodities || [], [commodityData?.commodities]);

   /**
    * Callback when the filters are changed, to update the search parameters.
    * @type {(function(*, *, *): Promise<void>)|*}
    */
   const handleFilterChange = useCallback(
      async (changedValues, allEditValues, allValues) => {
         const date = moment(allValues.date);

         if (date) {
            const useEditValues = removeEmpty({...allValues, id: undefined, search});
            setSearchParams({...useEditValues, date: date.format(DATE_DB_FORMAT)});
         }
      },
      [search, setSearchParams],
   );

   const [, handleChange, {getValue}] = useEditData(
      undefined,
      {
         ...searchParams,
         date: initialVariables.searchDate,
      },
      handleFilterChange,
   );

   /**
    * Callback when a date is changed.
    * @type {(function(*, *): void)|*}
    */
   const handleDateChange = useCallback(
      (endDate, name) => {
         const useDate = moment(endDate).endOf('month').subtract(12, 'hours');
         handleChange(undefined, undefined, undefined, {[name]: useDate}, name);
      },
      [handleChange],
   );

   /**
    * Callback to get the secondary label for the commodity.
    * @param row The data row from which to get the secondary label.
    * @return {*|string} The secondary label.
    */
   const handleGetSecondaryLabel = (row) => {
      const settlementPrice = find(priceData?.settlementPriceHistory, {commodityId: row?.id});
      return (
         formatCurrency(settlementPrice?.price || 0, {maximumSignificantDigits: 5}) +
         ', ' +
         formatCurrency(settlementPrice?.marginRequirement || 0, {maximumSignificantDigits: 5})
      );
   };

   const useIsLoading = loadingPriceHistory || loadingCommoditiyNames;

   return (
      <Stack height={'100%'} width='100%' display={'flex'} alignItems={'center'}>
         <LoadingValue isLoading={useIsLoading} size={40} thickness={3.6} showChildrenOnLoad={true} classes={classes}>
            {useIsLoading ? (
               <ListSkeleton classes={classes} />
            ) : (
               <ListSearchable
                  itemId={commodityId}
                  items={commodities}
                  isSearch
                  hasIndex
                  titleKey={'commodities.title'}
                  itemKey={'commodity.type'}
                  searchStart={
                     <DatePickerFHG
                        name={'date'}
                        onChange={handleDateChange}
                        value={getValue('date', moment().format(DATE_FORMAT_KEYBOARD))}
                        variant={'standard'}
                        format={DATE_DB_FORMAT}
                        openTo={'month'}
                        views={['year', 'month']}
                        fullWidth
                        style={{maxWidth: 220}}
                     />
                  }
                  getSecondaryLabel={handleGetSecondaryLabel}
                  searchPercent={'auto'}
                  searchWidth={'calc(100% - 40px)'}
                  hasAdd={false}
                  width={LIST_WIDTH_DEFAULT}
                  emptyMessageKey={'settlement.empty.message'}
               />
            )}
         </LoadingValue>
      </Stack>
   );
}
