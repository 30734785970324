import {Delete} from '@mui/icons-material';
import {Stack} from '@mui/material';
import {grey} from '@mui/material/colors';
import {makeStyles} from '@mui/styles';
import React from 'react';
import ButtonFHG from '../fhg/components/ButtonFHG';
import ProgressButton from '../fhg/components/ProgressButton';
import ConfirmButton from './ConfirmButton';

const useStyles = makeStyles(
   (theme) => ({
      buttonPanelStyle: {
         marginTop: theme.spacing(2),
         paddingLeft: theme.spacing(1),
      },
      deleteButtonStyle: {
         '&:not(.Mui-disabled)': {
            color: `${grey[600]} !important`,
         },
         '&:hover': {
            color: `${theme.palette.error.main} !important`,
         },
      },
   }),
   {name: 'EditButtonsStyles'},
);

/**
 * Button panel component to handle save, save and next, cancel and delete.
 *
 * @param isNew Indicates if the data item is new.
 * @param isDeleted Indicates if the data item is already deleted.
 * @param isSaving Indicates if the data item is being saved.
 * @param onDelete Callback when the item is deleted.
 * @param onUndelete Callback when the item is undeleted.
 * @param onClose Callback when the cancel button is selected.
 * @param type The type of the data item.
 * @param name The name of the data item
 * @param deleteTitleKey The localization key for the title.
 * @param deleteMessageKey The localization key for the delete message.
 * @param saveKey The localization key for the save button label
 * @param onSaveAndNext Callback when the save and next is selected. Save and next button is not displayed if this
 *                       callback is undefined
 * @param isDisabled
 * @return {JSX.Element}
 * @constructor
 */
export default function EditButtons({
   isNew,
   isDeleted,
   isSaving,
   isSubmitDisabled,
   onDelete,
   onUndelete,
   onClose,
   type,
   name,
   deleteTitleKey = 'confirmRemove.title',
   deleteMessageKey = 'confirmRemoveValue.message',
   saveKey = 'save.label',
   onSaveAndNext,
   isDisabled = false,
}) {
   const classes = useStyles();

   return (
      <Stack
         direction={'row'}
         className={classes.buttonPanelStyle}
         justifyContent={'space-between'}
         alignContent={'center'}
      >
         <Stack direction={'row'} spacing={2}>
            {onSaveAndNext && (
               <ProgressButton
                  isProgress={isSaving}
                  variant={'outlined'}
                  color='primary'
                  type={'submit'}
                  onClick={onSaveAndNext}
                  size='large'
                  labelKey='saveNext.label'
                  disabled={isSaving || isDeleted || isDisabled}
               />
            )}
            <ProgressButton
               isProgress={isSaving}
               variant='text'
               color='primary'
               type={'submit'}
               size='large'
               labelKey={saveKey}
               disabled={isSaving || isDeleted || isDisabled || isSubmitDisabled}
            />
            <ButtonFHG
               variant='text'
               size={'large'}
               labelKey={'cancel.button'}
               disabled={isSaving || isDeleted || isDisabled}
               onClick={onClose}
            />
         </Stack>
         {(!isDeleted && onDelete) ||
            (isDeleted && onUndelete && (
               <ConfirmButton
                  className={`${classes.deleteButtonStyle}`}
                  color={'error'}
                  titleKey={isDeleted ? 'confirmUndelete.title' : deleteTitleKey}
                  messageKey={isDeleted ? 'confirmUndeleteValue.message' : deleteMessageKey}
                  buttonLabelKey={isDeleted ? 'undelete.button' : 'delete.button'}
                  onConfirm={isDeleted ? onUndelete : onDelete}
                  values={{type, name}}
                  startIcon={<Delete />}
                  disabled={isSaving || isNew || isDisabled}
               />
            ))}
      </Stack>
   );
}
