import {Block} from '@mui/icons-material';
import {Delete} from '@mui/icons-material';
import {Fade} from '@mui/material';
import {Button} from '@mui/material';
import {ListItemButton} from '@mui/material';
import {Stack} from '@mui/material';
import {List, ListItem} from '@mui/material';
import {ListItemText} from '@mui/material';
import {ListItemAvatar} from '@mui/material';
import {Avatar} from '@mui/material';
import {makeStyles} from '@mui/styles';
import useTheme from '@mui/styles/useTheme';
import {capitalize} from 'lodash';
import {sortBy} from 'lodash';
import debounce from 'lodash/debounce';
import {matchSorter} from 'match-sorter';
import {useState} from 'react';
import {useRef} from 'react';
import {useCallback} from 'react';
import {useEffect} from 'react';
import React from 'react';
import {useParams} from 'react-router-dom';
import EmptyList from '../../components/EmptyList';
import {LIST_WIDTH_DEFAULT} from '../../Constants';
import {NEW_PATH} from '../../Constants';
import ConfirmIconButton from '../components/ConfirmIconButton';
import SearchFilter from '../components/table/SearchFilter';
import TypographyFHG from '../components/Typography';
import useMessage from '../hooks/useMessage';
import useNavigate from '../hooks/useNavigate';
import {colors} from '../../Constants';
import usePageTitle from '../hooks/usePageTitle';
import {numberFromText} from '../utils/Utils';

const useStyles = makeStyles(
   (theme) => ({
      fadeArea: {
         '&:hover .MuiListItemButton-root': {
            backgroundColor: '#F2F4F4',
         },
         '&:hover': {
            backgroundColor: '#F2F4F4',
         },
         '&:hover $fadeIn': {
            opacity: 1,
            transition: '1s',
            transitionDelay: '0.2s',
         },
      },
      fadeIn: {
         opacity: 0,
         marginTop: 'auto',
         marginBottom: 'auto',
      },
      buttonStyle: {
         textDecoration: 'underline !important',
      },
      titleStyle: {
         paddingLeft: theme.spacing(2),
         paddingRight: theme.spacing(2),
         fontWeight: '600 !important',
      },
   }),
   {name: 'ContactsStyles'}
);

export function stringAvatar(name, sx = {}) {
   if (name) {
      return {
         sx: {
            // eslint-disable-next-line
            bgcolor: colors[numberFromText(name) % colors.length],
            ...sx,
         },
         children: `${name.split(' ')[0][0]}${name.split(' ')?.[1]?.[0] || ''}`,
      };
   }
   return {sx};
}

export default function ContactList({
   contacts,
   onDelete,
   titleKey,
   itemKey,
   isPersonAvatar = false,
   isSearch = true,
   onEmptySearch,
}) {
   const {contactId} = useParams();
   const classes = useStyles();
   const theme = useTheme();
   const navigate = useNavigate();
   const itemTitle = useMessage(itemKey, 'Contact');
   const selectedRef = useRef();

   const [globalFilter, setGlobalFilter] = useState();
   const [filteredItems, setFilteredItems] = useState();

   const handleEditContact = (row) => () => {
      if (row?.id) {
         navigate(row?.id);
      } else {
         console.log('Cannot edit contact. Row id = ', row?.id);
      }
   };

   const handleDelete = (contact) => async () => {
      if (contact) {
         onDelete?.(contact);
      }
   };

   const handleIt = (event) => {
      event?.stopPropagation();
   };

   const handleAddContact = () => {
      navigate(NEW_PATH);
   };

   const handleSearch = useCallback(
      async (search, items) => {
         if (search !== undefined && items?.length > 0) {
            let matchedItems = matchSorter(items, search, {keys: ['name']}) || [];

            if (matchedItems.length <= 0 && onEmptySearch) {
               const deletedUser = await onEmptySearch(search);

               if (deletedUser) {
                  matchedItems = [deletedUser];
               }
            }

            setFilteredItems(matchedItems);
         } else {
            const sortedItems = sortBy(items, 'name');
            setFilteredItems(sortedItems || []);
         }
      },
      [onEmptySearch]
   );

   const handleGlobalFilterDebounced = useRef(debounce(handleSearch, 750)).current;

   const handleGlobalFilter = useCallback(
      (search) => {
         setGlobalFilter(search);
         handleGlobalFilterDebounced(search, contacts);
      },
      [handleGlobalFilterDebounced, contacts]
   );

   useEffect(() => {
      if (contacts?.length > 0) {
         if (globalFilter) {
            handleSearch(globalFilter, contacts);
         } else {
            const sortedContacts = sortBy(contacts, 'name');
            setFilteredItems(sortedContacts);
         }
      } else {
         setFilteredItems([]);
      }
   }, [contacts, globalFilter, handleSearch]);

   const handleSelectRef = (params) => {
      selectedRef.current = params;

      if (params?.scrollIntoViewIfNeeded) {
         params.scrollIntoViewIfNeeded();
      } else {
         params?.scrollIntoView(true);
      }
   };

   usePageTitle({titleKey});

   return (
      <Fade in={!!filteredItems}>
         <Stack direction={'column'} sx={{height: '100%', maxWidth: LIST_WIDTH_DEFAULT, p: 3}}>
            <Stack name='Title Frame' sx={{mr: 2}} direction='row' justifyContent='space-between'>
               {titleKey && (
                  <TypographyFHG
                     className={classes.titleStyle}
                     color={theme.palette.text.secondary}
                     variant={'h5'}
                     id={titleKey}
                  />
               )}
               <Button className={classes.buttonStyle} variant='text' onClick={handleAddContact}>
                  {`Add ${itemTitle}`}
               </Button>
            </Stack>
            {isSearch && <SearchFilter globalFilter={globalFilter} setGlobalFilter={handleGlobalFilter} sx={{ml: 2}} />}
            <List
               dense
               sx={{
                  width: '100%',
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: '100%',
                  paddingRight: 2,
               }}
            >
               {filteredItems?.map((row) => (
                  <ListItem
                     key={row.id}
                     onClick={handleEditContact(row)}
                     className={classes.fadeArea}
                     disableGutters
                     secondaryAction={
                        onDelete ? (
                           <ConfirmIconButton
                              edge='end'
                              className={`${classes.fadeIn} ${classes.deleteButtonStyle}`}
                              onConfirm={handleDelete(row)}
                              values={{type: 'contact', name: row.name}}
                              size={'small'}
                           >
                              <Delete fontSize={'small'} />
                           </ConfirmIconButton>
                        ) : undefined
                     }
                  >
                     <ListItemButton dense selected={row.id === contactId} ref={handleSelectRef}>
                        <ListItemAvatar>
                           {row?.isDeleted ? (
                              <Block />
                           ) : isPersonAvatar ? (
                              <Avatar
                                 sx={{
                                    width: 30,
                                    height: 30,
                                    // eslint-disable-next-line
                                    bgcolor: row.hasUserAccount ? theme.palette.primary.main : undefined,
                                 }}
                              />
                           ) : (
                              <Avatar {...stringAvatar(row.name)} />
                           )}
                        </ListItemAvatar>
                        <ListItemText
                           style={{minWidth: 150}}
                           primary={row.name}
                           primaryTypographyProps={{
                              fontSize: 18,
                              fontWeight: 'medium',
                              letterSpacing: 0,
                           }}
                           secondary={
                              <a
                                 onClick={handleIt}
                                 href={`mailto:${row.email}`}
                                 style={{color: theme.palette.text.secondary}}
                              >
                                 {row.email}
                              </a>
                           }
                        />
                     </ListItemButton>
                  </ListItem>
               ))}
            </List>
            <EmptyList
               items={contacts}
               isFiltering={false}
               isSearching={!!globalFilter}
               filteredItems={filteredItems}
               values={{title: capitalize(itemTitle), type: itemTitle}}
            />
         </Stack>
      </Fade>
   );
}
