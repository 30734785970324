import PropTypes from 'prop-types';
import React from 'react';
import {useRecoilState} from 'recoil';
import {useRecoilValue} from 'recoil';
import {progressGlobal, progressState} from '../hooks/useProgress';
import Loading from './Loading';

ProgressIndicator.propTypes = {
   hasBackdrop: PropTypes.bool, // Indicates if the backdrop should display.
};

/**
 * Component to show the progress spinner.
 */
export default function ProgressIndicator({isProgress = false, hasBackdrop = false, isGlobal = false, ...props}) {
   const [useGlobal] = useRecoilState(progressGlobal);
   const isInProgress = useRecoilValue(progressState);

   if ((isProgress || isInProgress) && useGlobal === isGlobal) {
      return <Loading hasBackdrop={hasBackdrop} {...props} />;
   } else {
      return null;
   }
}
