import {useEffect} from 'react';
import {useState} from 'react';
import React from 'react';
import {useRecoilState} from 'recoil';
import {ADMIN_PATH} from '../../../Constants';
import {HERO_IMAGE} from '../../../Constants';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {welcomeShowState} from '../../../fhg/components/security/Authenticator';
import TypographyFHG from '../../../fhg/components/Typography';
import {makeStyles} from '@mui/styles';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles(
   () => ({
      style: {
         fontSize: 36,
         fontWeight: 'bold',
         textAlign: 'center',
         lineHeight: 1.1,

         '& p': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
         },
      },
   }),
   {name: 'WelcomeStyles'}
);

export default function Welcome({onClose}) {
   const classes = useStyles();
   const location = useLocation();
   const [, setShowWelcome] = useRecoilState(welcomeShowState);
   const [open, setOpen] = useState(false);

   useEffect(() => {
      if (location.pathname.indexOf('/' + ADMIN_PATH) === 0) {
         setShowWelcome({isShowDialog: false, isHideBackground: false});
         setOpen(false);
      } else {
         setOpen(true);
      }
   }, [location, setShowWelcome]);

   return (
      <Dialog open={open || false} maxWidth={'md'}>
         <Stack sx={{m: 3}} direction={'column'} alignItems={'center'} spacing={2}>
            <img alt={'Hero'} src={HERO_IMAGE} width={700} />
            <TypographyFHG id='welcome.title' className={classes.style} hasLineBreaks />
            <Button variant='contained' size='large' sx={{width: 130}}>
               <span style={{fontSize: 24}}>Tour</span>
            </Button>
            <Button
               variant={'text'}
               size='large'
               onClick={() => {
                  setOpen(false);
                  setShowWelcome({isShowDialog: false, isHideBackground: false});
                  onClose?.();
               }}
            >
               Login
            </Button>
         </Stack>
      </Dialog>
   );

   //
}
