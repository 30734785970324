import {useQuery} from '@apollo/client';
import {uniqWith} from 'lodash';
import {isEqual} from 'lodash';
import {omit} from 'lodash';
import uniqueId from 'lodash/uniqueId';
import {useState} from 'react';
import {useCallback} from 'react';
import {useMemo} from 'react';
import {useRef} from 'react';
import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {errorState} from '../../components/ErrorStateSnackbar';
import useMessage from '../useMessage';
import {progressGlobal} from '../useProgress';
import useProgress from '../useProgress';

export const requeryState = {};

export default function useQueryFHG(query, options, typeKey, showGlobalProgress = true, showLoading = true, queryPath) {
   const theUniqueId = useRef(uniqueId()).current;
   const [isFetchMore, setIsFetchMore] = useState(false);

   const [, /*Unused*/ setProgress] = useProgress(theUniqueId);
   const type = useMessage(typeKey, 'Unknown');

   const setErrorState = useSetRecoilState(errorState);

   const result = useQuery(query, options);
   const setProgressGlobal = useSetRecoilState(progressGlobal);

   useEffect(() => {
      if (queryPath) {
         const variables = options?.variables;
         const list = requeryState[queryPath] || [];

         requeryState[queryPath] = uniqWith([...list, {query, variables, queryPath}], isEqual);
      }
   }, [queryPath, options, query]);

   useEffect(() => {
      if (showLoading) {
         setProgressGlobal(showGlobalProgress);
      }
      return () => {
         setProgressGlobal(true);
      };
   }, [showGlobalProgress, setProgressGlobal, showLoading]);

   useEffect(() => {
      return () => {
         setProgress(false);
      };
   }, [setProgress]);

   useEffect(() => {
      if (showLoading !== false) {
         // fetchMore doesn't handle loading correctly so force to true for fetchMore.
         setProgress(isFetchMore || result?.loading);
      }
   }, [isFetchMore, result?.loading, setProgress, showLoading]);

   useEffect(() => {
      if (result?.error) {
         console.log('Error type', typeKey);
         console.log(result?.error, result?.error.stackTrace);
         setErrorState({error: result?.error, errorKey: 'load.error', values: {type, message: result?.error.message}});
      }
   }, [result?.error, setErrorState, typeKey, type]);

   /**
    * fetchMore that handles loading which result doesn't do correctly.
    * @type {(function(*): Promise<void>)|*}
    */
   const fetchMoreWithLoading = useCallback(
      async (options) => {
         setIsFetchMore(true);
         const fetchMoreResults = await result.fetchMore(options);
         setIsFetchMore(false);
         return fetchMoreResults;
      },
      [result],
   );

   return useMemo(() => {
      const currentResult = {...result, fetchMore: fetchMoreWithLoading};

      // Force the loading to true and data to undefined since it isn't handled right for fetchMore.
      if (isFetchMore) {
         currentResult.loading = true;
      }
      return [result.data, currentResult];
   }, [result, fetchMoreWithLoading, isFetchMore]);
}
