import {forOwn} from 'lodash';
import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

/**
 * Hook to get the search params as an object. Similiar to useSearchParams, but converts the search to an object.
 * @param [getDefaultSearch] Optional getter function to provide defaults for those not found in the search params.
 * @return {[{[p: string]: string},((nextInit?: (URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit)), navigateOpts?: NavigateOptions) => void)]}
 */
export const useCustomSearchParams = (getDefaultSearch) => {
   const [search, setSearch] = useSearchParams();
   const searchAsObject = useMemo(() => {
      const object = {...(getDefaultSearch?.() || {}), ...Object.fromEntries(new URLSearchParams(search))};
      forOwn(object, (value, key) => {
         if (value === 'undefined') {
            object[key] = undefined;
         } else if (value === 'null') {
            object[key] = null;
         } else if (value === 'true' || value === 'false') {
            object[key] = value === 'true';
         } else {
            const number = Number(value);
            if (!isNaN(number)) {
               object[key] = number;
            }
         }
      });

      return object;
   }, [search, getDefaultSearch]);

   const setSearchAsObject = useCallback(
      (valueOrFunction) => {
         let result = typeof valueOrFunction === 'function' ? valueOrFunction(searchAsObject) : valueOrFunction;
         setSearch(result);
         return result;
      },
      [searchAsObject, setSearch]
   );

   return [searchAsObject, setSearchAsObject];
};
