import {Search} from '@mui/icons-material';
import Fade from '@mui/material/Fade';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import {Box} from '@mui/system';
import React from 'react';
import {useRecoilValue} from 'recoil';
import TypographyFHG from '../fhg/components/Typography';
import {progressState} from '../fhg/hooks/useProgress';

const useStyles = makeStyles(
   (theme) => ({
      pStyle: {
         color: theme.palette.text.primary,
         '& > p': {
            textAlign: 'center',
            'margin-block-start': 0,
            'margin-block-end': 0,
         },
      },
   }),
   {name: 'EmptyListStyles'}
);

/**
 * Component to show the empty state for a list of items.
 *
 * @param filteredItems The array of filtered items to determine empty state.
 * @param items The array of items to determine empty state.
 * @param title The title of the empty state message.
 * @param titleKey the title key of the empty state message
 * @param messageKey The message key of the empty state message.
 * @param values The values for the tile and message.
 * @param isFiltering Indicates if the list is being filtered.
 * @param filteredMessageKey The message key of the empty state message for filtered items.
 * @param isSearching Indicates if the list is being searched.
 * @param searchMessageKey The message key for the search message.
 * @param filterAndSearchMessageKey The message key for the filter and search message.
 * @param link An action link.
 * @param linkText The text to show for the action link.
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function EmptyList({
   filteredItems,
   items,
   title,
   titleKey = 'empty.title',
   messageKey = 'empty.message',
   values,
   isFiltering,
   filteredMessageKey = 'emptyFilter.message',
   isSearching,
   searchMessageKey = 'emptySearch.message',
   filterAndSearchMessageKey = 'emptySearchFilter.message',
   link,
   linkText,
}) {
   const classes = useStyles();
   const isInProgress = useRecoilValue(progressState);
   const containerRef = React.useRef(null);

   const hasNoItems = items && items.length <= 0;

   if (
      !items ||
      !filteredItems ||
      (items.length > 0 && filteredItems.length > 0) ||
      items.length < filteredItems.length ||
      isInProgress
   ) {
      return null;
   }

   let useMessageKey =
      hasNoItems && !isFiltering
         ? messageKey
         : isFiltering && isSearching
         ? filterAndSearchMessageKey
         : isFiltering
         ? filteredMessageKey
         : searchMessageKey;

   return (
      <Box
         ref={containerRef}
         sx={{m: 1}}
         display={'flex'}
         flexDirection={'column'}
         alignItems={'center'}
         justifyContent={'center'}
      >
         <Search style={{fontSize: '48px'}} color={'textPrimary'} />
         <TypographyFHG variant={'h6'} id={titleKey} values={values} className={classes.pStyle}>
            {title}
         </TypographyFHG>
         {useMessageKey && (
            <Fade in={!!useMessageKey}>
               <TypographyFHG
                  className={classes.pStyle}
                  variant={'subtitle'}
                  id={useMessageKey}
                  hasLineBreaks
                  values={values}
               />
            </Fade>
         )}
         {link && (
            <p>
               <Link to={link}>{linkText}</Link>
            </p>
         )}
      </Box>
   );
}
