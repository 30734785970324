import {Avatar} from '@mui/material';
import {Stack} from '@mui/material';
import {Box} from '@mui/material';
import {grey} from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import {makeStyles} from '@mui/styles';
import {defer} from 'lodash';
import moment from 'moment/moment';
import {useRef} from 'react';
import {useEffect} from 'react';
import {useMemo} from 'react';
import React from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import EditButtons from '../../../components/EditButtons';
import TextFieldFHG from '../../../components/TextFieldFHG';
import {DATE_DB_FORMAT} from '../../../Constants';
import {SETTLEMENT_PRICE_HISTORY_ALL_WHERE_INITIALIZE_QUERY} from '../../../data/QueriesGL';
import {GET_LAST_PRICE_BY_COMMODITY_QUERY} from '../../../data/QueriesGL';
import {COMMODITY_BY_ID_QUERY} from '../../../data/QueriesGL';
import {getSettlementPriceHistoryCacheQueries} from '../../../data/QueriesGL';
import {SETTLEMENT_PRICE_HISTORY_UNDELETE} from '../../../data/QueriesGL';
import {SETTLEMENT_PRICE_HISTORY_DELETE} from '../../../data/QueriesGL';
import {SETTLEMENT_PRICE_HISTORY_CREATE} from '../../../data/QueriesGL';
import {SETTLEMENT_PRICE_HISTORY_BY_ID_QUERY} from '../../../data/QueriesGL';
import Form from '../../../fhg/components/edit/Form';
import ProgressIndicator from '../../../fhg/components/ProgressIndicator';
import TypographyFHG from '../../../fhg/components/Typography';
import useEditQueryData from '../../../fhg/hooks/data/useEditQueryData';
import useQueryFHG from '../../../fhg/hooks/data/useQueryFHG';
import {useCustomSearchParams} from '../../../fhg/hooks/useCustomSearchParams';
import {stringAvatar} from '../../../fhg/pages/ContactList';
import {formatMessage} from '../../../fhg/utils/Utils';
import delay from 'lodash/delay';

const useStyles = makeStyles(
   (theme) => ({
      buttonPanelStyle: {
         marginTop: theme.spacing(2),
         paddingLeft: theme.spacing(1),
      },
      formStyle: {
         overflow: 'hidden',
         display: 'flex',
         flexDirection: 'column',
         width: '100%',
         height: '100%',
         maxWidth: 600,
      },
      frameStyle: {
         margin: theme.spacing(1),
         padding: theme.spacing(2),
      },
      deleteButtonStyle: {
         '&:not(.Mui-disabled)': {
            color: `${grey[600]} !important`,
         },
         '&:hover': {
            color: `${theme.palette.error.main} !important`,
         },
      },
   }),
   {name: 'CommodityMonthlyStyles'},
);

const REQUIREMENTS = ['commodityId', 'date'];

/**
 * Component to edit the monthly settlement price for commodities.
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function CommodityMonthlyEdit() {
   const {commodityId} = useParams();
   const [searchParams, setSearchParams] = useCustomSearchParams();
   const ref = useRef();
   const formRef = useRef();

   // variables used to query for the SettlementPriceHistory for the list.
   const initialVariables = useMemo(() => {
      const initialDate = searchParams?.date ? moment(searchParams?.date) : moment().endOf('month');
      const initialDateFormatted = moment(initialDate).format(DATE_DB_FORMAT);
      return {
         searchDate: initialDateFormatted,
         date: initialDateFormatted,
      };
   }, [searchParams?.date]);

   const classes = useStyles();
   const intl = useIntl();

   const [commodityData, {loading: isLoadingCommodity}] = useQueryFHG(
      COMMODITY_BY_ID_QUERY,
      {variables: {id: commodityId}, errorPolicy: 'all'},
      'commodity.type',
      false,
   );
   const [data, {loading: isLoadingPrice}] = useQueryFHG(SETTLEMENT_PRICE_HISTORY_ALL_WHERE_INITIALIZE_QUERY, {
      variables: {commodityId, ...initialVariables},
   });
   const [lookupLastPriceData, {loading: isLoadingPriceData}] = useQueryFHG(GET_LAST_PRICE_BY_COMMODITY_QUERY, {
      variables: {commodityIds: [commodityId], date: initialVariables?.date},
      skip: !commodityId || !initialVariables?.date,
   });
   const isNew = lookupLastPriceData?.lastPrice?.[0]?.customPrice === undefined;
   // Instance used for creating a new PriceHistory.
   const initialSettlementPriceHistory = React.useMemo(() => {
      return {
         date: initialVariables?.date,
         price: lookupLastPriceData?.lastPrice?.[0]?.customPrice,
         commodityId,
      };
   }, [initialVariables?.date, lookupLastPriceData?.lastPrice, commodityId]);

   const {isSaving, getValue, handleChange, handleClose, handleSubmit, resetValues} = useEditQueryData(
      isNew,
      data?.settlementPriceHistory?.[0]?.id,
      'price.type',
      false,
      false,
      SETTLEMENT_PRICE_HISTORY_BY_ID_QUERY,
      SETTLEMENT_PRICE_HISTORY_CREATE,
      SETTLEMENT_PRICE_HISTORY_DELETE,
      getSettlementPriceHistoryCacheQueries,
      initialSettlementPriceHistory,
      SETTLEMENT_PRICE_HISTORY_UNDELETE,
      undefined,
      REQUIREMENTS,
      undefined,
      'SettlementPriceHistory',
      undefined,
      undefined,
      {pathname: '..', search: {index: null}},
      initialVariables,
   );

   useEffect(() => {
      // Only works to focus and select after data is in cache.
      delay(() => {
         const element = document.getElementById('price');
         if (element) {
            element.focus();
            element.select();
         }
      }, 500);
   }, [data]);

   /**
    * Callback for keys. Escape closes the panel, Enter saves and moves to the next commodity, and tab moves to the
    * next commodity without saving.
    * @param event
    */
   const handleEditKey = (event) => {
      if (event.key === 'Escape') {
         event.preventDefault();
         handleClose();
      } else if (event.key === 'Enter') {
         event.preventDefault();
         handleSaveAndNext();
      } else if (event.key === 'Tab') {
         event?.preventDefault();
         event?.stopPropagation();

         setSearchParams((params) => ({...params, index: (searchParams?.index || -1) + 1}));
      }
   };

   /**
    * Callback to save the price and move to the next commodity. The next commodity is based on the index in the search
    * params.
    *
    * @param event The event triggering the save and next.
    */
   const handleSaveAndNext = (event) => {
      event?.stopPropagation();
      event?.preventDefault();

      (async () => {
         if (formRef?.current?.checkValidity()) {
            const useIndex = searchParams?.index ?? -1;
            try {
               const result = await handleSubmit(undefined, false);
               if (!result.errors) {
                  defer(() => {
                     resetValues();
                     setSearchParams((params) => ({...params, index: useIndex + 1}));
                  });
               }
            } catch (e) {
               console.log(e);
            }
         } else {
            // Reporting validity on the whole form doesn't seem to work. Report on each input too.
            document.getElementById('price')?.reportValidity();
            document.getElementById('marginRequirement')?.reportValidity();
            formRef?.current?.reportValidity();
         }
      })();
   };
   const isReady = !isLoadingCommodity && !isLoadingPrice && !isLoadingPriceData;

   return (
      <Fade in={true}>
         <Form ref={formRef} onSubmit={handleSubmit} className={classes.formStyle}>
            <ProgressIndicator isProgress={!isReady} />
            <Box name={'Commodity Monthly Edit Root'} className={classes.frameStyle} direction={'column'} spacing={2}>
               <Stack name={'Commodity Title'} direction={'row'} alignItems={'center'} spacing={2}>
                  <Avatar
                     {...stringAvatar(commodityData?.commodity?.name, {
                        width: 100,
                        height: 100,
                        ml: 2,
                        mb: 2,
                        fontSize: 48,
                     })}
                  />
                  <TypographyFHG variant={'h5'}>{commodityData?.commodity?.name || ''}</TypographyFHG>
               </Stack>
               <Divider sx={{mb: 1}} />
               <Box
                  sx={{
                     '&': {overflow: 'auto', mb: 1, px: 2},
                     '& > *': {my: 1, display: 'inline-flex', flexDirection: 'column'},
                  }}
               >
                  <TextFieldFHG
                     id={'price'}
                     name={'price'}
                     ref={ref}
                     label={formatMessage(intl, 'settlement.commodityMonth.label')}
                     onChange={handleChange}
                     autoFocus
                     required
                     isFormattedNumber
                     type={'number'}
                     value={getValue('price')}
                     onKeyDown={handleEditKey}
                     disabled={isSaving || !isReady}
                  />
                  {lookupLastPriceData?.lastPrice?.[0]?.price ? (
                     <TypographyFHG variant={'subtitle'}>
                        MtoM Price {lookupLastPriceData?.lastPrice?.[0]?.price}
                     </TypographyFHG>
                  ) : null}

                  <Divider sx={{width: '100%'}} flexItem />
                  <TextFieldFHG
                     id={'marginRequirement'}
                     name={'marginRequirement'}
                     ref={ref}
                     label={formatMessage(intl, 'settlement.marginRequirement.label')}
                     onChange={handleChange}
                     autoFocus
                     required
                     isFormattedNumber
                     type={'number'}
                     value={getValue('marginRequirement')}
                     onKeyDown={handleEditKey}
                     disabled={isSaving || !isReady}
                     sx={{mt: 2}}
                  />
               </Box>
               <EditButtons
                  name={commodityData?.commodity?.name}
                  type={'settlement price'}
                  onClose={handleClose}
                  isNew={isNew}
                  isSaving={isSaving}
                  isDisabled={!isReady}
                  deleteTitleKey={'settlement.confirmRemove.title'}
                  deleteMessageKey={'settlement.confirmRemoveValue.message'}
                  onSaveAndNext={handleSaveAndNext}
               />
            </Box>
         </Form>
      </Fade>
   );
}
