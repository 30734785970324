import {lazy} from 'react';
import React from 'react';
import {Routes, Route, Outlet, Navigate} from 'react-router-dom';
import NoMatch from '../components/NoMatch';
import {SDR_PATH} from '../Constants';
import {DEAL_PASSWORD_PATH} from '../Constants';
import {CASH_ITEM_PATH, COMMODITY_MONTHLY_PATH, NEW_CASH, TWO_FACTOR_AUTHENTICATION_PATH} from '../Constants';
import {
   SETTLEMENT_PATH,
   STATEMENT_PATH,
   REPORT_ITEM_PATH,
   POSITION_REPORT_PATH,
   REPORT_PATH,
   DEAL_LIST_PATH,
   DEAL_TABLE_PATH,
   SIGN_OUT_PATH,
   USER_ITEM_PATH,
   CLIENT_ITEM_PATH,
   LOCATION_ITEM_PATH,
   SETTINGS_PATH,
   TRADER_PATH,
   ONBOARD_PATH,
   INFO_PATH,
   SOCIAL_PATH,
   CONTACT_PATH,
   USER_PATH,
   LOCATION_PATH,
   DEAL_PATH,
   CLIENT_PATH,
   COMMODITIES_PATH,
   ADMIN_PATH,
   LANDING_PATH,
   CASH_ACTIVITY_PATH,
   DEAL_DATA_PATH,
} from '../Constants';
import SuspenseLx from '../fhg/components/SuspenseLx';
// import AddCash from './admin/cash-activity/AddCash';
import CommodityMonthlyEdit from './admin/settlement/CommodityMonthlyEdit';
import SettlementCommodityList from './admin/settlement/SettlementCommodityList';
import {TwoFactorAuthentication} from './admin/twoFactorAuthentication/TwoFactorAuthentication';
const ErrorStateSnackbar = lazy(() => import('../fhg/components/ErrorStateSnackbar'));

const Information = lazy(() => import('./admin/client/Information'));
const ClientEdit = lazy(() => import('./admin/client/ClientEdit'));
const ClientContactEdit = lazy(() => import('./admin/client/ClientContactEdit'));
const ClientContactList = lazy(() => import('./admin/client/ClientContactList'));
const Contacts = lazy(() => import('./admin/client/Contacts'));
const Onboard = lazy(() => import('./admin/client/Onboard'));
const Social = lazy(() => import('./admin/client/Social'));
const SettlementList = lazy(() => import('./admin/settlement/SettlementList'));
const SettlementReport = lazy(() => import('./admin/settlement/SettlementReport'));
const StatementList = lazy(() => import('./admin/statement/StatementList'));
const StatementReport = lazy(() => import('./admin/statement/StatementReport'));
const TraderEdit = lazy(() => import('./admin/trader/TraderEdit'));
const TraderList = lazy(() => import('./admin/trader/TraderList'));
const PositionReport = lazy(() => import('./admin/report/PositionReport'));
const UserList = lazy(() => import('./admin/user/UserList'));
const UserEdit = lazy(() => import('./admin/user/UserEdit'));
const LocationEdit = lazy(() => import('./admin/location/LocationEdit'));
const LocationList = lazy(() => import('./admin/location/LocationList'));
const DealEdit = lazy(() => import('./admin/deal/DealEdit'));
const DealList = lazy(() => import('./admin/deal/DealList'));
const DealTable = lazy(() => import('./admin/deal/DealTable'));
const DealDataImport = lazy(() => import('./admin/deal/DataImport'));
const DealPassword = lazy(() => import('./admin/deal/DealPassword'));
const CommodityEdit = lazy(() => import('./admin/commodity/CommodityEdit'));
const CommodityList = lazy(() => import('./admin/commodity/CommodityList'));
const Dashboard = lazy(() => import('./admin/Dashboard'));
const ClientList = lazy(() => import('./admin/client/ClientList'));
const Landing = lazy(() => import('./client/landing/Landing'));
const TypeList = lazy(() => import('./admin/setting/TypeList'));
const SignOut = lazy(() => import('../fhg/pages/SignOut'));
const ListAndEdit = lazy(() => import('../fhg/pages/ListAndEdit'));
const ProgressIndicator = lazy(() => import('../fhg/components/ProgressIndicator'));
const CashActivity = lazy(() => import('./admin/cash-activity/CashList'));
const AddCash = lazy(() => import('./admin/cash-activity/AddCash'));
const SdrPanel = lazy(() => import('./admin/sdr/SdrPanel'));

/**
 * Main component accessible only if the user has been authenticated. Contains the routing for the application.
 *
 * Reviewed:
 */
export default function Main() {
   return (
      <>
         <ProgressIndicator isGlobal={true} />
         <ErrorStateSnackbar />
         <Routes>
            <Route path={'/'} element={<Outlet />}>
               {/*Client Paths*/}
               <Route path={`:${CLIENT_ITEM_PATH}`} element={<SuspenseLx C={Landing} />}>
                  <Route
                     path={POSITION_REPORT_PATH}
                     element={<SuspenseLx e={<PositionReport showTitle={false} />} />}
                     loader={() => 'Position Report'}
                  />
                  <Route path={STATEMENT_PATH} element={<SuspenseLx e={<StatementReport showTitle={false} />} />} />
                  <Route path={SETTLEMENT_PATH} element={<SuspenseLx e={<SettlementReport showTitle={false} />} />} />
                  <Route path={DEAL_PATH} element={<SuspenseLx e={<DealEdit useClientDefault={true} />} />} />
               </Route>
               <Route index element={<SuspenseLx C={Landing} />} />
               {/*Admin Paths*/}
               <Route path={ADMIN_PATH} element={<Dashboard />}>
                  <Route path={LANDING_PATH} element={<Outlet />}>
                     <Route path={`:${CLIENT_ITEM_PATH}`} element={<Landing isAdmin />}>
                        <Route
                           path={POSITION_REPORT_PATH}
                           element={<SuspenseLx e={<PositionReport showTitle={false} />} />}
                           loader={() => 'Position Report'}
                        />
                        <Route
                           path={STATEMENT_PATH}
                           element={<SuspenseLx e={<StatementReport showTitle={false} />} />}
                        />
                        <Route
                           path={SETTLEMENT_PATH}
                           element={<SuspenseLx e={<SettlementReport showTitle={false} />} />}
                        />
                        <Route path={DEAL_PATH} element={<SuspenseLx e={<DealEdit />} />} />
                     </Route>
                     <Route index element={<SuspenseLx e={<Landing isAdmin />} />} />
                  </Route>
                  <Route path={REPORT_PATH} element={<Outlet />}>
                     <Route path={POSITION_REPORT_PATH} element={<SuspenseLx e={<PositionReport />} />}>
                        <Route path={REPORT_ITEM_PATH} element={<SuspenseLx e={<PositionReport />} />} />
                     </Route>
                     <Route
                        path={CASH_ACTIVITY_PATH}
                        element={<SuspenseLx e={<ListAndEdit ListComponent={CashActivity} width={'100%'} />} />}
                     >
                        <Route path={':' + CASH_ITEM_PATH} element={<SuspenseLx C={AddCash} />} />
                        <Route path={NEW_CASH} element={<SuspenseLx C={AddCash} />} />
                     </Route>
                     <Route
                        path={STATEMENT_PATH}
                        element={<SuspenseLx e={<ListAndEdit ListComponent={StatementList} width={'100%'} />} />}
                     >
                        <Route path={':' + CLIENT_ITEM_PATH} element={<SuspenseLx C={StatementReport} />} />
                     </Route>
                     <Route
                        exact
                        path={`${SETTLEMENT_PATH}/${COMMODITY_MONTHLY_PATH}`}
                        element={<SuspenseLx e={<ListAndEdit ListComponent={SettlementCommodityList} />} />}
                     >
                        <Route path=':commodityId' element={<SuspenseLx e={<CommodityMonthlyEdit />} />} />
                     </Route>
                     <Route
                        path={SETTLEMENT_PATH}
                        element={<SuspenseLx e={<ListAndEdit ListComponent={SettlementList} width={'100%'} />} />}
                     >
                        <Route path={':' + CLIENT_ITEM_PATH} element={<SuspenseLx e={<SettlementReport />} />} />
                     </Route>
                     <Route path={SDR_PATH} element={<SuspenseLx e={<SdrPanel />} />} />
                  </Route>

                  <Route path={DEAL_PATH} element={<Outlet />}>
                     <Route path={DEAL_TABLE_PATH} element={<SuspenseLx e={<DealTable />} />}>
                        <Route path=':dealId' element={<SuspenseLx e={<DealEdit />} />} />
                     </Route>
                     <Route path={DEAL_LIST_PATH} element={<SuspenseLx e={<ListAndEdit ListComponent={DealList} />} />}>
                        <Route path={':dealId'} element={<SuspenseLx e={<DealEdit />} />} />
                     </Route>
                     <Route path={DEAL_DATA_PATH} element={<SuspenseLx e={<DealDataImport />} />} />
                     <Route path={DEAL_PASSWORD_PATH} element={<SuspenseLx e={<DealPassword />} />} />
                  </Route>
                  <Route path={CLIENT_PATH} element={<SuspenseLx e={<ListAndEdit ListComponent={ClientList} />} />}>
                     <Route path={':' + CLIENT_ITEM_PATH} element={<SuspenseLx e={<ClientEdit />} />}>
                        <Route exact path={CONTACT_PATH} element={<SuspenseLx e={<Contacts />} />}>
                           <Route exact path={':contactId'} element={<SuspenseLx e={<ClientContactEdit />} />} />
                           <Route index element={<SuspenseLx e={<ClientContactList />} />} />
                        </Route>
                        <Route exact path={SOCIAL_PATH} element={<SuspenseLx e={<Social />} />} />
                        <Route exact path={ONBOARD_PATH} element={<SuspenseLx e={<Onboard />} />} />
                        <Route exact path={INFO_PATH} element={<SuspenseLx e={<Information />} />} />
                        <Route
                           index
                           render={() => {
                              return <Navigate to='CONTACT_PATH' />;
                           }}
                           replace
                        />
                     </Route>
                  </Route>
                  <Route
                     path={COMMODITIES_PATH}
                     element={<SuspenseLx e={<ListAndEdit ListComponent={CommodityList} />} />}
                  >
                     <Route path=':commodityId' element={<SuspenseLx e={<CommodityEdit />} />} />
                  </Route>
                  <Route path={TRADER_PATH} element={<SuspenseLx e={<ListAndEdit ListComponent={TraderList} />} />}>
                     <Route path=':contactId' element={<SuspenseLx e={<TraderEdit />} />} />
                  </Route>
                  <Route path={LOCATION_PATH} element={<SuspenseLx e={<ListAndEdit ListComponent={LocationList} />} />}>
                     <Route path={':' + LOCATION_ITEM_PATH} element={<SuspenseLx e={<LocationEdit />} />} />
                  </Route>
                  <Route path={USER_PATH} element={<SuspenseLx e={<ListAndEdit ListComponent={UserList} />} />}>
                     <Route path={`:${USER_ITEM_PATH}`} element={<SuspenseLx e={<UserEdit />} />} />
                  </Route>
                  <Route path={SETTINGS_PATH} element={<SuspenseLx e={<ListAndEdit ListComponent={TypeList} />} />} />
                  <Route
                     path={TWO_FACTOR_AUTHENTICATION_PATH}
                     element={<SuspenseLx e={<TwoFactorAuthentication />} />}
                  />
                  <Route path={SIGN_OUT_PATH} element={<SignOut />} />
               </Route>
               <Route path={SIGN_OUT_PATH} element={<SignOut />} />
               <Route path='*' element={<NoMatch />} />
            </Route>
         </Routes>
      </>
   );
}
