import {indexOf} from 'lodash';
import {useState} from 'react';
import {useEffect} from 'react';
import {useMatch} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {atom} from 'recoil';
import {CLIENT_ITEM_PATH} from '../../../Constants';
import {ADMIN_PATH} from '../../../Constants';
import {DEFAULT_PATH} from '../../../Constants';
import {ADMIN_GROUP} from '../../../Constants';
import {USERS_ALL_WHERE_QUERY} from '../../../data/QueriesGL';
import useLazyQueryFHG from '../../hooks/data/useLazyQueryFHG';

export const userState = atom({
   key: 'userGroupsStateKey',
   default: {name: '', username: '', groups: [], signOut: undefined, sub: undefined, isAdmin: false, id: ''},
});

export const userStatus = atom({
   key: 'userStatus',
   default: {},
});

export default function AuthenticatedUser({authState, children}) {
   const [user, setUser] = useRecoilState(userState);
   const [isLoaded, setIsLoaded] = useState(false);

   const navigate = useNavigate();
   const [dataFetch] = useLazyQueryFHG(USERS_ALL_WHERE_QUERY, undefined, 'user.type');
   const match = useMatch({path: `:${CLIENT_ITEM_PATH}`, end: true});

   const location = useLocation();

   useEffect(() => {
      async function execute() {
         let clientId, name, id;

         if (authState) {
            const groups = authState?.user?.signInUserSession?.idToken?.payload?.['cognito:groups'] ?? [];
            const sub = authState?.user?.attributes?.sub;
            const isAdmin = indexOf(groups, ADMIN_GROUP) >= 0;

            setUser({username: authState?.user?.username, groups, signOut: authState.signOut, sub, isAdmin});

            if (sub && sub !== user.sub) {
               try {
                  const results = await dataFetch({variables: {cognitoSub: sub}, skip: !sub});

                  if (results) {
                     const user = results?.data?.users?.[0];
                     clientId = user?.clientContactClientId;
                     name = user?.contactName;
                     id = user?.id;
                  }

                  // Set the name and clientId after those are fetched.
                  setUser({
                     clientId,
                     name,
                     username: authState?.user?.username,
                     groups,
                     signOut: authState.signOut,
                     sub,
                     isAdmin,
                     id,
                  });
               } catch (e) {
                  console.log(e);
               }
            }

            if (clientId) {
               navigate(`/${clientId}`);
            } else if (isAdmin && (location.pathname === DEFAULT_PATH || match)) {
               navigate(ADMIN_PATH);
            }
            setIsLoaded(true);
         }
      }
      if (!user?.sub || user?.username !== authState?.user?.username) {
         execute();
      }
   }, [authState, dataFetch, location.pathname, match, navigate, setUser, user?.sub, user?.username]);

   return isLoaded ? children : null;
}
