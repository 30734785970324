import {capitalize} from 'lodash';
import {defer} from 'lodash';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import {useMemo} from 'react';
import {useCallback} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import {v4 as uuid} from 'uuid';
import {getCityCacheQueries} from '../../../data/QueriesGL';
import {CITY_CREATE_UPDATE} from '../../../data/QueriesGL';
import {STATE_ALL_QUERY} from '../../../data/QueriesGL';
import {CITY_ALL_QUERY} from '../../../data/QueriesGL';
import useEditData from '../../components/edit/useEditData';
import {getDataItem} from '../../utils/DataUtil';
import {cacheUpdate} from '../../utils/DataUtil';
import {cacheUndelete} from '../../utils/DataUtil';
import {cacheAdd} from '../../utils/DataUtil';
import {cacheDelete} from '../../utils/DataUtil';
import useKeyDown from '../useKeyDown';
import useMessage from '../useMessage';
import useNavigate from '../useNavigate';
import usePromptFHG from '../usePromptFHG';
import useMutationFHG from './useMutationFHG';
import {requeryState} from './useQueryFHG';
import useQueryFHG from './useQueryFHG';

export default function useEditQueryData(
   isNew, //Indicates if a new item is being edited.
   id, //ID of the item being edited
   typeKey, // typeKey for the data query
   hasCity = false, // Indicates if the city editing should be included.
   hasState = false, //Indicates if the state editing should be included.
   dataQuery, // Query to get the data for the item
   updateMutation, // Mutation for the item.
   deleteMutation, // Mutation to delete the item.
   getCacheQueries, // Call to get the queries to be updated on delete or create.
   newItem, // Item set with all default values without id. (id doesn't need to be set)
   undeleteMutation, // Mutation to undelete the item.
   onSubmit, // Callback when the handleSubmit is called. Returns the modifications only. If the return is null, it has not changed.
   requiredEditValues, // The required fields for submit from the defaultValues.
   deleteIdKey = 'id', // The id of the field to delete by.
   createType, // If the Type of the created type doesn't match the capitalized type from typeKey, explicitly set it.
   showGlobalProgress = true,
   showProgress = true,
   closeUrl = '..',
   updateVariables,
   queryPath,
) {
   const navigate = useNavigate();
   const [isSaving, setIsSaving] = useState(false);

   const variables = {id};
   const [itemData] = useQueryFHG(
      dataQuery,
      {variables, skip: !id || id === 'new'},
      typeKey,
      showGlobalProgress,
      showProgress,
      queryPath,
   );

   const [itemCreateUpdate] = useMutationFHG(updateMutation, {errorPolicy: 'all'});
   const [itemDelete] = useMutationFHG(deleteMutation);
   const [itemUndelete] = useMutationFHG(undeleteMutation, {skip: !undeleteMutation});
   const [cityCreateUpdate] = useMutationFHG(CITY_CREATE_UPDATE);
   const type = useMessage(typeKey);
   const item = useMemo(() => getDataItem(itemData, type), [itemData, type]);
   const [isLoading, setIsLoading] = useState(true);

   const editData = useEditData(undefined, requiredEditValues);
   const [
      editValues,
      handleChange,
      {
         isChanged,
         defaultValues,
         setDefaultValues,
         getValue,
         setValue,
         setIsChanged,
         resetValues,
         resetValue,
         handleChangeWithName,
         handleInputChange,
         handleDateChange,
         handleArrayChange,
      },
   ] = editData;
   const [cityAutocomplete, setCityAutocomplete] = useState('');
   const [stateAutocomplete, setStateAutocomplete] = useState('');

   const [cityData] = useQueryFHG(CITY_ALL_QUERY, {skip: !hasCity}, 'city.type', showGlobalProgress, showProgress);
   const [stateData] = useQueryFHG(STATE_ALL_QUERY, {skip: !hasState}, 'state.type', showGlobalProgress, showProgress);

   usePromptFHG({when: isChanged, messageKey: 'leavePage'});
   const cityId = item?.cityId;
   const stateId = item?.stateId;

   useEffect(() => {
      let useItem = !isNew ? item : {id: uuid(), ...newItem};

      if (isArray(useItem)) {
         useItem = useItem[0];
      }
      resetValues(useItem);
      setCityAutocomplete('');
      setStateAutocomplete('');
   }, [isNew, setDefaultValues, itemData, item, newItem]);

   useEffect(() => {
      if (stateData && item?.stateId) {
         setStateAutocomplete(find(stateData?.states, {id: item?.stateId}));
      }
   }, [item?.stateId, stateData, stateId]);

   useEffect(() => {
      if (cityData && item?.cityId) {
         setCityAutocomplete(find(cityData?.cities, {id: item?.cityId}));
      }
   }, [cityData, cityId, item?.cityId]);

   /**
    * Close the information panel.
    */
   const handleClose = useCallback(
      (hasNavigate = true) => {
         resetValues(item);
         setIsLoading(false);

         if (hasNavigate) {
            defer(() => {
               navigate(closeUrl, {state: {outletExpanded: false}});
            });
         }
      },
      [closeUrl, item, navigate, resetValues],
   );

   /**
    * Submit the changes to the server.
    * @return {Promise<void>}
    */
   const handleSubmit = useCallback(
      async (event = {}, isCloseOnSuccess = true) => {
         const changedData = onSubmit?.(editValues, defaultValues);

         try {
            setIsSaving(true);
            if (editValues.city) {
               const variables = {id: uuid(), name: editValues.city};
               await cityCreateUpdate({
                  variables,
                  optimisticResponse: {
                     city: {
                        ...variables,
                        label: variables.name,
                        isDeleted: false,
                        __typename: 'City',
                     },
                     __typename: 'Mutation',
                  },
                  update: cacheAdd(getCityCacheQueries(), 'city'),
               });
               editValues.cityId = variables.id;
            }
            if (defaultValues?.isDeleted) {
               await itemUndelete({
                  variables: {id},
                  optimisticResponse: {[`${type}_UnDelete`]: 1},
               });
            }

            const variables = {id: uuid(), ...editValues, ...(changedData || {})};
            const result = await itemCreateUpdate({
               variables,
               optimisticResponse: {
                  __typename: 'Mutation',
                  [type]: {
                     __typename: createType || capitalize(type),
                     ...defaultValues,
                     ...variables,
                     isDeleted: false,
                  },
               },
               update: cacheUpdate(
                  getCacheQueries(variables.id, updateVariables),
                  isNew ? undefined : editValues.id || variables.id,
                  type,
               ),
            });
            if (!result.errors) {
               setIsChanged(false);
               if (isCloseOnSuccess) {
                  handleClose();
               }
            }
            return result;
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      },
      [
         cityCreateUpdate,
         createType,
         defaultValues,
         editValues,
         getCacheQueries,
         handleClose,
         id,
         isNew,
         itemCreateUpdate,
         itemUndelete,
         onSubmit,
         setIsChanged,
         type,
         updateVariables,
      ],
   );

   const handleOtherKeys = (event) => {
      if ((window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) && event.keyCode === 83) {
         event?.preventDefault();
         handleSubmit();
      }
   };

   const handleKey = useKeyDown(handleClose, handleSubmit, false, handleOtherKeys);

   const handleDelete = useCallback(async () => {
      try {
         setIsSaving(true);

         if (id) {
            await itemDelete({
               variables: {[deleteIdKey]: id},
               optimisticResponse: {[`${type}_Delete`]: 1},
               update: cacheDelete(getCacheQueries(id), id, undefined, deleteIdKey),
            });
         } else {
            console.log('Item could not be found to delete', id);
         }

         handleClose();
      } catch (e) {
         console.log(e);
      } finally {
         setIsSaving(false);
      }
   }, [deleteIdKey, getCacheQueries, handleClose, id, itemDelete, type]);

   const handleUndelete = useCallback(async () => {
      try {
         setIsSaving(true);

         if (id) {
            await itemUndelete({
               variables: {id},
               optimisticResponse: {[`${type}_UnDelete`]: 1},
               update: cacheUndelete(requeryState[typeKey], item),
            });
         } else {
            console.log('Item could not be found to delete', id);
         }

         handleClose();
      } catch (e) {
         console.log(e);
      } finally {
         setIsSaving(false);
      }
   }, [handleClose, id, item, itemUndelete, type, typeKey]);

   const handleAutocompleteChange = (name) => (event, value, reason) => {
      let newValue;

      if (reason === 'selectOption') {
         newValue = {[name]: value.id};
      }
      handleChange(event, value, reason, newValue, name);

      if (name === 'cityId') {
         setCityAutocomplete(find(cityData?.cities, {id: value.id}));
      } else if (name === 'stateId') {
         setStateAutocomplete(find(stateData?.states, {id: value.id}));
      }
   };

   return {
      isSaving,
      isLoading,
      getValue,
      setValue,
      editValues,
      defaultValues,
      resetValue,
      resetValues,
      cities: cityData?.cities || [],
      states: stateData?.states || [],
      cityAutocomplete,
      stateAutocomplete,
      handleChange,
      handleChangeWithName,
      handleAutocompleteChange,
      handleSubmit,
      handleDelete,
      handleUndelete,
      handleClose,
      handleKey,
      handleInputChange,
      handleDateChange,
      handleArrayChange,
   };
}
