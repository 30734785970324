import {Pagination} from '@mui/material';
import {ListItemButton} from '@mui/material';
import {Stack, Button, List, ListItemText} from '@mui/material';
import Fade from '@mui/material/Fade';
import {makeStyles} from '@mui/styles';
import useTheme from '@mui/styles/useTheme';
import {has} from 'lodash';
import {orderBy} from 'lodash';
import {capitalize} from 'lodash';
import castArray from 'lodash/castArray';
import debounce from 'lodash/debounce';
import {matchSorter} from 'match-sorter';
import {useRef} from 'react';
import React, {useCallback, useState} from 'react';
import EmptyList from '../../components/EmptyList';
import {LIST_WIDTH_DEFAULT} from '../../Constants';
import {NEW_PATH} from '../../Constants';
import SearchFilter from '../components/table/SearchFilter';
import TypographyFHG from '../components/Typography';
import {useCustomSearchParams} from '../hooks/useCustomSearchParams';
import useEffect from '../hooks/useEffect';
import useMessage from '../hooks/useMessage';
import useNavigate from '../hooks/useNavigate';
import usePageTitle from '../hooks/usePageTitle';
import {removeEmpty} from '../utils/DataUtil';

const SEARCH_KEYS_DEFAULT = ['name'];
const ID_KEY_DEFAULT = 'id';

const useStyles = makeStyles(
   (theme) => ({
      titleStyle: {
         // paddingLeft: theme.spacing(2),
         paddingRight: theme.spacing(2),
         fontWeight: '600 !important',
      },
      listItemStyle: {
         cursor: 'pointer',
         '&:nth-of-type(odd)': {
            backgroundColor: '#fafafa',
         },
      },
      buttonStyle: {
         whiteSpace: 'nowrap',
      },
      paginationStyle: {
         display: 'flex',
         justifyContent: 'center',
      },
   }),
   {name: 'ListSearchableStyles'},
);

/**
 * The component to show a list of items. The list can be searchable.
 *
 * @param itemId The id of the selected item.
 * @param titleKey The message key for the title.
 * @param itemKey The message key for the item (e.g. Add [item title] for the add item button).
 * @param items The items in the list.
 * @param isSearch Indicates if the searchFilter should be shown.
 * @param onEmptySearch The callback when the search doesn't return any results. The returned items will be used
 *   instead. Useful for Undelete to return the deleted item.
 * @param {function} [getName] If there is not an appropriate name field, get the name from the getName.
 * @param {function} [getSecondaryLabel] - A secondary label to be displayed under the primary label.
 * @param idKey=['id']  The name of the ID field. Defaults to id.
 * @param [searchKeys=['name']]
 * @param width Width of the search component
 * @param secondaryKey The key for the secondary text for ListItemText.
 * @param headerActions Action components for the header.
 * @param searchStart The component before the search filter.
 * @param searchPercent The flex percent of the search filter.
 * @param searchWidth The width of the search component.
 * @param hasAdd Indicates if the "Add {item}" button should be shown.
 * @param emptyMessageKey The message key for the empty list message.
 * @param page The current page of the data.
 * @param pageCount The total number of pages of data.
 * @param onPageChange Callback when the page changes.
 * @param children The children of the component displayed after the search filter.
 * @return {JSX.Element}
 * @constructor
 */
export default function ListSearchable({
   itemId,
   titleKey,
   itemKey,
   items,
   isSearch,
   onEmptySearch,
   getName,
   getSecondaryLabel,
   idKey = ID_KEY_DEFAULT,
   searchKeys = SEARCH_KEYS_DEFAULT,
   width = LIST_WIDTH_DEFAULT,
   secondaryKey,
   headerActions,
   searchStart,
   searchPercent = '100%',
   searchWidth = '100%',
   hasAdd = true,
   emptyMessageKey,
   page,
   pageCount,
   onPageChange,
   hasIndex = false,
   children,
   className,
   Component,
}) {
   const classes = useStyles();
   const theme = useTheme();
   const navigate = useNavigate(true);
   const itemTitle = useMessage(itemKey, 'Client');
   const selectedRef = useRef();
   const [searchParams, setSearchParams] = useCustomSearchParams();
   const [globalFilter, setGlobalFilter] = useState();
   const [filteredItems, setFilteredItems] = useState();

   const handleSearch = useCallback(
      (search) => {
         setSearchParams(removeEmpty({...searchParams, search}));
      },
      [searchParams, setSearchParams],
   );

   const handleGlobalFilterDebounced = useRef(debounce(handleSearch, 750)).current;

   const handleGlobalFilter = useCallback(
      (search) => {
         setGlobalFilter(search);
         handleGlobalFilterDebounced(search);
      },
      [handleGlobalFilterDebounced],
   );

   /**
    * Select the row on click.
    * @param row The item clicked.
    * @return {function(...[*]=)}
    */
   const handleEdit = useCallback(
      (row, index) => () => {
         if (row?.[idKey]) {
            if (hasIndex) {
               navigate(row?.[idKey], undefined, true, {index});
            } else {
               navigate(row?.[idKey]);
            }
         } else {
            console.log('Cannot edit item. Row id = ', row?.[idKey]);
         }
      },
      [idKey, navigate],
   );

   useEffect(() => {
      async function search() {
         let filteredItems = undefined;

         if (items) {
            if (items.length > 0) {
               if (searchParams) {
                  const search = searchParams.search;

                  if (search) {
                     if (search !== globalFilter) {
                        setGlobalFilter(search);
                     }
                     filteredItems = matchSorter(items, search, {keys: searchKeys}) || [];

                     if (filteredItems.length <= 0 && onEmptySearch) {
                        const results = await onEmptySearch(search);
                        if (results) {
                           filteredItems = castArray(results);
                        }
                     }
                  } else {
                     filteredItems = orderBy(
                        items,
                        [(item) => (typeof item?.name === 'string' ? item?.name?.toLowerCase() : item?.name)],
                        ['asc'],
                     );
                  }
                  if (hasIndex && searchParams.index !== undefined) {
                     navigate(filteredItems[searchParams.index]?.[idKey]);
                  }
               } else {
                  filteredItems = orderBy(
                     items,
                     [(item) => (typeof item?.name === 'string' ? item?.name?.toLowerCase() : item?.name)],
                     ['asc'],
                  );
               }
            } else {
               filteredItems = [];
            }
         }
         setFilteredItems(filteredItems);
      }
      search();
   }, [searchParams, items, handleSearch, searchKeys, onEmptySearch, globalFilter, idKey, itemId, handleEdit]);

   const handleAdd = () => {
      navigate(NEW_PATH);
   };

   const handleSelectRef = (params) => {
      selectedRef.current = params;

      if (params?.scrollIntoViewIfNeeded) {
         params.scrollIntoViewIfNeeded();
      } else {
         params?.scrollIntoView(true);
      }
   };

   let isFiltering;
   const searchParamsCount = Object.keys(removeEmpty(searchParams))?.length || 0;
   const isSearching = has(searchParams, 'search');

   if (searchParamsCount >= 1) {
      isFiltering = searchParamsCount !== 1 || !isSearching;
   } else {
      isFiltering = false;
   }

   usePageTitle({titleKey});

   return (
      <Stack
         direction={'column'}
         sx={{height: '100%', width, p: 3}}
         display={'flex'}
         position={'relative'}
         className={className}
      >
         <Stack name='Title Frame' sx={{mr: 2, mb: 2}} direction='row' justifyContent='space-between'>
            {titleKey && (
               <TypographyFHG
                  className={classes.titleStyle}
                  color={theme.palette.text.secondary}
                  variant={'h5'}
                  id={titleKey}
               />
            )}
            <Stack spacing={2} direction={'row'}>
               {hasAdd && itemKey && (
                  <Button className={classes.buttonStyle} variant='text' onClick={handleAdd}>
                     {`Add ${capitalize(itemTitle)}`}
                  </Button>
               )}
               {headerActions}
            </Stack>
         </Stack>
         <Stack
            direction={'row'}
            display={'flex'}
            width='100%'
            flexWrap={'wrap'}
            flex={'0 0 auto'}
            alignItems={'center'}
            justifyItems={'space-between'}
         >
            {searchStart}
            <div style={{flex: `0 0 ${searchPercent}`, width: searchWidth}}>
               {isSearch && <SearchFilter initialFilter={searchParams.search} setGlobalFilter={handleGlobalFilter} />}
            </div>
            {children}
         </Stack>
         <Fade in={!!filteredItems}>
            <Stack width={'100%'} height={'100%'} overflow={'hidden'}>
               <List
                  dense
                  className={classes.listStyle}
                  sx={{
                     width: '100%',
                     position: 'relative',
                     overflow: 'auto',
                     maxHeight: '100%',
                     paddingRight: 2,
                  }}
               >
                  {filteredItems?.map((row, index) => (
                     <ListItemButton
                        key={'listItemButton ' + index + ' ' + row?.[idKey]}
                        ref={row?.[idKey] === itemId ? handleSelectRef : undefined}
                        onClick={handleEdit(row, index)}
                        selected={row?.[idKey] === itemId}
                        className={classes.listItemStyle}
                        disableGutters
                     >
                        {Component ? (
                           <Component data={row} />
                        ) : (
                           <ListItemText
                              style={{minWidth: 150}}
                              primary={row.name || getName?.(row) || 'Untitled'}
                              primaryTypographyProps={{
                                 fontSize: 18,
                                 fontWeight: 'medium',
                                 letterSpacing: 0,
                              }}
                              secondary={secondaryKey ? row?.[secondaryKey] : getSecondaryLabel?.(row)}
                           />
                        )}
                     </ListItemButton>
                  ))}
               </List>
               {pageCount > 1 && filteredItems?.length > 0 && (
                  <Pagination
                     count={pageCount}
                     page={page}
                     onChange={onPageChange}
                     sx={{mt: 1}}
                     size={'small'}
                     className={classes.paginationStyle}
                  />
               )}
               <EmptyList
                  items={items}
                  isFiltering={isFiltering}
                  isSearching={isSearching}
                  messageKey={emptyMessageKey}
                  filteredItems={filteredItems}
                  values={{title: capitalize(itemTitle), type: itemTitle}}
               />
            </Stack>
         </Fade>
      </Stack>
   );
}
