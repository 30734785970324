import {Close} from '@mui/icons-material';
import {Stack} from '@mui/material';
import {IconButton} from '@mui/material';
import {InputAdornment} from '@mui/material';
import React from 'react';
import TextFieldFHG from '../../../components/TextFieldFHG';

/**
 * The TextField with the clear icon after the text field. Used for the DatePickerFHG to provide the clear functionality.
 */
export default function TextFieldWithClearable({clearable, onDateClear, disabled, ...params}) {
   const handleClear = () => {
      onDateClear?.();
   };

   let inputProps = {};

   if (clearable) {
      inputProps = {
         endAdornment: (
            <InputAdornment key='clear' position='end'>
               <IconButton onMouseDown={handleClear} disabled={disabled} size={'small'} edge={'start'}>
                  <Close />
               </IconButton>
            </InputAdornment>
         ),
      };
   }

   return (
      <TextFieldFHG
         {...params}
         InputProps={{
            ...(params?.InputProps || {}),
            endAdornment: [
               inputProps.endAdornment,
               <Stack key={'defaultEndAdornment'} flexDirection={'row'} flexWrap={'nowrap'}>
                  {params?.InputProps?.endAdornment}
               </Stack>,
            ],
         }}
      />
   );
}
