import gql from 'graphql-tag';

export const CLIENT_FRAGMENT = gql`
   fragment clientInfo on Client {
      id
      name
      accountId
      addressLineOne
      addressLineTwo
      c3AccountId
      website
      cityId
      city {
         id
         name
      }
      stateId
      state {
         id
         name
         abbreviation
      }
      zipCode
      hasFinancials
      gtcSentDate
      fein
      lei
      kycCompleted
      locationIdList
      flashpointContactId
      paperworkTypeId
      paperworkType {
         id
         name
      }
      w9StatusId
      w9Status {
         id
         name
      }
      isDeleted
      reportFrequency
      confidence
      varModel
      showSubtotals
      showOptionPremiums
      optionPremiumsStartDate
      sdrExcluded
   }
`;

export const CITY_FRAGMENT = gql`
   fragment CityInfo on City {
      id
      name
      label: name
      isDeleted
   }
`;

export const CLIENT_CONTACT_FRAGMENT = gql`
   fragment clientContactInfo on ClientContact {
      id
      name
      label: name
      email
      isPrimary
      hasUserAccount
      contactType {
         id
         name
      }
      contactTypeId
      clientId
   }
`;

export const CONTACT_SOCIAL_FRAGMENT = gql`
   fragment contactSocialInfo on ContactSocial {
      id
      name
      socialTypeId
      type: socialType {
         id
         name
      }
      clientContactId
      url
   }
`;

export const PHONE_FRAGMENT = gql`
   fragment phoneInfo on ContactPhone {
      id
      phone
      phoneTypeId
      type: phoneType {
         id
         name
      }
      clientContactId
   }
`;

export const STATE_FRAGMENT = gql`
   fragment StateInfo on State {
      id
      name
      label: name
      abbreviation
   }
`;

export const CLIENT_SOCIAL_FRAGMENT = gql`
   fragment clientSocialInfo on ClientSocial {
      id
      clientId
      socialTypeId
      type: socialType {
         id
         name
      }
      url
   }
`;

export const LOCATION_FRAGMENT = gql`
   fragment locationInfo on Location {
      id
      addressLineOne
      addressLineTwo
      cityId
      clientIdList
      direction
      name
      lat
      lng
      physDeliveryMethodId
      stateId
      zipCode
   }
`;

export const LOCATION_NAME_FRAGMENT = gql`
   fragment locationNameInfo on Location {
      id
      name
      label: name
   }
`;

export const COMMODITY_FRAGMENT = gql`
   fragment commodityInfo on Commodity {
      id
      name
      label: name
      note
      commodityTypeId
      euroAsianCode
      exchangeTypeId
      futOptCode
      measurementId
      psCodeTypeId
      underlyingTypeId
      expireSetting
      isDeleted
      sdrExcluded
   }
`;

export const COMMODITY_NAME_FRAGMENT = gql`
   fragment commodityNameInfo on Commodity {
      id
      name
      label: name
   }
`;

export const USER_FRAGMENT = gql`
   fragment userInfo on User {
      id
      username
      contactName
      name: contactName
      email
      isDeleted
      clientContactClientId
      mfaEnabled
   }
`;

export const TYPE_FRAGMENT = gql`
   fragment typeInfo on Type {
      id
      name
      label: name
      category
   }
`;

export const DEAL_FRAGMENT = gql`
   fragment dealInfo on Deal {
      id
      buyOrSell
      createdDateTime
      callOrPut
      client {
         id
         name
         accountId
      }
      clientId
      cmeMonthCode
      cmeYearCode
      commission
      commissionRatePerLot
      commodity {
         id
         name
         futOptCode
         fepCode
      }
      commodityId
      customVolume
      c3Contract
      volume
      dealGroupId
      dealType {
         id
         name
      }
      dealTypeId
      dealGroupEnd
      dealGroupStart
      end
      flashpointContact {
         id
         name
      }
      flashpointContactId
      futOrOpt
      hash
      ir
      irFee
      isDeleted
      isHidden
      locationId
      note
      premium
      price
      psMethodI
      cmeCode
      psYearCode
      refMonth
      start
      strike
      underlying
      lastPriceIsZero
      manualLastPrice
      customExpiration
      approved
   }
`;

export const DEAL_REPORT_FRAGMENT = gql`
   fragment dealStatementInfo on Deal {
      id
      buyOrSell
      createdDateTime
      callOrPut
      clientId
      cmeMonthCode
      cmeYearCode
      commission
      commissionRatePerLot
      commodityId
      cpBalance
      customVolume
      c3Contract
      volume
      dealGroupId
      dealTypeId
      dealGroupEnd
      dealGroupStart
      end
      flashpointContactId
      hash
      ir
      irFee
      isDeleted
      isHidden
      lastPrice
      locationId
      futOrOpt
      note
      premium
      optionPremium
      price
      psMethodI
      psYearCode
      refMonth
      start
      startDate
      strike
      totalVaR
      underlying
      delta
      individualVaR
      quantity
   }
`;

export const DEAL_NAME_FRAGMENT = gql`
   fragment dealNameInfo on Deal {
      #      id: dealGroupId
      id
      commodityId
      dealGroupStart
      dealGroupEnd
      clientId
      end
      flashpointContactId
      dealGroupId
   }
`;

const POSITION_REPORT_MONTHLY_FRAGMENT = gql`
   fragment positionReportMonthlyInfo on PositionReportMonthly {
      id
      month
      value
      deltaValue
      year
   }
`;

export const POSITION_REPORT_FRAGMENT = gql`
   fragment positionReportInfo on PositionReportType {
      id
      name
      value
      deltaValue
      errorsOccurred
      errorText
      monthlyValues {
         ...positionReportMonthlyInfo
      }
      commodities {
         id
         name
         value
         deltaValue
         monthlyValues {
            ...positionReportMonthlyInfo
         }
      }
   }
   ${POSITION_REPORT_MONTHLY_FRAGMENT}
`;

export const POSITION_REPORT_GROUP_FRAGMENT = gql`
   fragment positionReportGroupInfo on PositionReportType {
      id
      name
      value
      deltaValue
      monthlyValues {
         ...positionReportMonthlyInfo
      }
   }
   ${POSITION_REPORT_MONTHLY_FRAGMENT}
`;

export const STATEMENT_HISTORY_FRAGMENT = gql`
   fragment statementHistoryInfo on StatementHistory {
      id
      beginningCash
      cashActivity
      capitalRequirements
      optionPremium
      clientId
      startDate
      removedDate
      snapshotDate
      updatedDateTime
   }
`;

export const SETTLEMENT_PRICE_HISTORY_FRAGMENT = gql`
   fragment settlementPriceHistoryInfo on SettlementPriceHistory {
      id
      date
      price
      commodityId
      marginRequirement
      isDeleted
   }
`;

export const LAST_PRICE_LOOKUP_FRAGMENT = gql`
   fragment lastPriceLookupInfo on LastPriceLookup {
      id
      customPrice
      price
   }
`;

export const CASH_ACTIVITY_FRAGMENT = gql`
   fragment cashActivity on CashActivity {
      id
      type
      amount
      activityDate
      clientId
      isDeleted
      createdByUserId
      createdDateTime
      updatedByUserId
      updatedDateTime
   }
`;

export const SDR_LOOKUP_FRAGMENT = gql`
   fragment sdrLookupInfo on SdrLookup {
      report {
         Action
         AssetClass
         Leg1CapCategory
         Leg2CapCategory
         SubmissionType
         UTI
         ReportingPartyID
         ReportingPartyIDSource
         NonReportingPartyID
         NonReportingPartyIDSource
         NonRptPartyAssetManagerID
         RptPartyAssetManagerID
         ReportingEntityID
         ReportingEntityIDSource
         AllocationIndicator
         Cleared
         Event
         ClearingAccountOrigin
         ClearingMemberID
         ClearingReceiptDateTime
         DeliveryType
         EffectiveDate
         EventDateTime
         ExecutionDateTime
         ReportingDateTime
         ExpirationDate
         FinalSettlementDate
         InstrumentType
         Jurisdiction
         Leg1UnderlyingAssetOrContractType
         Leg1NotionalAmount
         Leg1CommodityDesc
         Leg1UnderlyingAssetOrContractSubType
         Leg1CallAmount
         Leg1PutAmount
         Leg1NotionalCurrency
         Leg1CallCurrency
         Leg1PutCurrency
         Leg2UnderlyingAssetOrContractType
         Leg2UnderlyingAssetOrContractSubType
         ProductName
         AttachmentPoint
         BuyerID
         SellerID
         Leg1PayerID
         Leg1ReceiverID
         ClearingSwapUSI1
         ClearingSwapUSI2
         ClearingSwapUTI1
         ClearingSwapUTI2
         EventID
         FirstExerciseDate
         Leg1FixedRateDayCount
         Leg1FixedRatePaymentFrequencyMultiplier
         Leg1FloatingRatePaymentFrequencyMultiplier
         Leg1NotionalEffectiveDate
         Leg1QuantityFrequencyMultiplier
         Leg1QuantityFrequencyPeriod
         Leg1QuantityUnit
         Leg1ResetFrequencyMultiplier
         Leg1SettlementCurrency
         Leg1SpreadCurrency
         Leg1SpreadNotation
         Leg1TotalNotionalQuantity
         Leg1UnderlierID
         Leg1UnderlierIDSource
         Leg2UnderlierID
         Leg2CommodityDesc
         Leg2UnderlierIDSource
         Leg2CallCurrency
         Leg2PutCurrency
         Leg2FixedRate
         Leg2FixedRatePaymentFrequencyMultiplier
         Leg2FloatingRatePaymentFrequencyMultiplier
         Leg2QuantityUnit
         OptionPremiumAmount
         OptionPremiumCurrency
         OptionPremiumPaymentDate
         OptionStrikePrice
         OptionStrikePriceNotation
         OptionStrikePriceCurrency
         OptionStyle
         OptionType
         PlatformID
         SEFOrDCMIndicator
         AnonymousExecutionIndicator
         AmendmentIndicator
         ShortMessageIndicator
         NonRptPartyFedEntityIndicator
         NonRptPartyFinEntityIndicator
         NonRptPartySDOrMSPIndicator
         RptPartyFedEntityIndicator
         RptPartyFinEntityIndicator
         RptPartySDOrMSPIndicator
         CustomBasketIndicator
         PackageIndicator
         BlockTradeIndicator
         LargeNotionalOffFacilitySwapIndicator
         PrimeBrokerageTransactionIndicator
         NonStandardTermIndicator
         PhysicalCommodityContractIndicator
         PostPricedSwapIndicator
      }
      reportStatus {
         id
         date
         lastError
         status
      }
   }
`;

export const SDR_REPORT_STATUS_FRAGMENT = gql`
   fragment sdrReportStatusInfo on SdrReportStatus {
      id
      lastError
      date
      status
   }
`;

export const FEP_ADDRESS_FRAGMENT = gql`
   fragment fepAddressInfo on FEPAddress {
      addressLineOne
      addressLineTwo
      city
      state
      zipCode
   }
`;
