import {useLazyQuery} from '@apollo/client';
import {isEqual} from 'lodash';
import {uniqWith} from 'lodash';
import uniqueId from 'lodash/uniqueId';
import {useCallback} from 'react';
import {useRef} from 'react';
import {useSetRecoilState} from 'recoil';
import {useEffect} from 'react';
import {errorState} from '../../components/ErrorStateSnackbar';
import useMessage from '../useMessage';
import {progressGlobal} from '../useProgress';
import useProgress from '../useProgress';
import {requeryState} from './useQueryFHG';

export default function useLazyQueryFHG(query, optionsProp, typeKey, showGlobalProgress = true, showLoading = true, queryPath) {
   const theUniqueId = useRef(uniqueId()).current;
   const [, /*Unused*/ setProgress] = useProgress(theUniqueId);
   const type = useMessage(typeKey, 'Unknown');

   const setErrorState = useSetRecoilState(errorState);

   const [queryFunction, result] = useLazyQuery(query, optionsProp);
   const setProgressGlobal = useSetRecoilState(progressGlobal);
   const resolveRef = useRef();

   useEffect(() => {
      if ((result.called || result.data) && !result.loading && resolveRef.current) {
         resolveRef.current(result);
         resolveRef.current = undefined;
      }
   }, [result]);

   useEffect(() => {
      if (showLoading) {
         setProgressGlobal(showGlobalProgress);
      }
      return () => {
         setProgressGlobal(true);
      };
   }, [showGlobalProgress, setProgressGlobal, showLoading]);

   useEffect(() => {
      return () => {
         setProgress(false);
      };
   }, [setProgress]);

   useEffect(() => {
      if (showLoading) {
         setProgress(result?.loading);
      }
   }, [result?.loading, setProgress, showLoading]);

   useEffect(() => {
      if (result.error) {
         console.log('Error type', typeKey);
         console.log(result.error, result.error.stackTrace);
         setErrorState({error: result.error, errorKey: 'load.error', values: {type, message: result.error.message}});
      }
   }, [result?.error, setErrorState, typeKey, type]);

   const queryFunctionLocal = useCallback(
      (options) => {
         if (queryPath) {
            // const variables = omit(options?.variables, ['offset', 'limit']);
            const variables = {...(options?.variables || {}), ...(optionsProp.variables || {})};
            const list = requeryState[queryPath] || [];

            requeryState[queryPath] = uniqWith([...list, {query, variables, queryPath}], isEqual);
         }

         return queryFunction(options);
      },
      [optionsProp?.variables, query, queryFunction, queryPath],
   );

   return [queryFunctionLocal, result];
}
