import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import {useCallback} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import React, {forwardRef} from 'react';
import TextFieldFHG from '../../../components/TextFieldFHG';
import {DATE_FORMAT_KEYBOARD} from '../../../Constants';
import ValidateTarget from '../ValidateTarget';
import TextFieldWithClearable from './TextFieldWithClearable';

/**
 * DatePicker component that handles clearable and default styles.
 * @param name Name of the component.
 * @param value The value of the component.
 * @param onChange Callback when the value changes.
 * @param variant The variant of the TextField.
 * @param disabled If true, the picker and text field are disabled.
 * @param clearable If true, the value can be cleared by pressing the 'X' button.
 * @param helperText The helper text for the TextField
 * @param FormHelperTextProps The FormHelperTextProps for the TextField
 * @param props The other props are passed to the DatePicker.
 * @returns {JSX.Element}
 * @constructor
 */
const DatePickerFHG = forwardRef(function DatePickerFHG(
   {
      name,
      value,
      onChange,
      variant,
      disabled,
      clearable,
      error,
      helperText,
      FormHelperTextProps,
      format,
      isDateOnly = true,
      onError,
      onAccept,
      labelKey,
      required,
      maxDate,
      minDate,
      ...props
   },
   ref,
) {
   const [date, setDate] = useState();
   const [internalError, setInternalError] = useState();
   const [refresh, setRefresh] = useState(Date.now());

   useEffect(() => {
      let useDate;
      if (!date || value !== date?.format?.(format)) {
         if (value && format) {
            if (isDateOnly) {
               useDate = moment.utc(value, format);
            } else {
               useDate = moment(value, format);
            }
         } else if (typeof value === 'string') {
            useDate = moment(value);
         } else {
            useDate = value === '' ? undefined : value;
         }
         setDate(useDate);
         setRefresh();
      }
   }, [format, isDateOnly, value]);

   const handleDateAccept = (date) => {
      handleDateChange(date);
      onAccept?.(date);
   };

   const handleDateChange = (newDate) => {
      if (newDate) {
         if (!maxDate || newDate.isSameOrBefore(maxDate, 'day')) {
            if (!minDate || newDate.isSameOrAfter(minDate, 'day')) {
               setDate(newDate);
               if (!newDate?.isValid()) {
                  onError?.(newDate);
               } else {
                  setInternalError(null);
               }
               onChange(format ? moment(newDate).format(format) : newDate, name);
            } else {
               setInternalError(`Enter a date after ${minDate.format(DATE_FORMAT_KEYBOARD)}.`);
               onError?.(newDate);
            }
         } else {
            setInternalError(`Enter a date before ${maxDate.format(DATE_FORMAT_KEYBOARD)}.`);
            onError?.(newDate);
         }
      } else if (required) {
         setInternalError('Enter a date.');
         onError?.(newDate);
      } else {
         setDate(newDate);
         setInternalError(null);
         onChange(newDate);
      }
   };

   const ignoreDateChange = (newDate, keyboardInputString) => {
      if (newDate) {
         if (!maxDate || newDate.isSameOrBefore(maxDate, 'day')) {
            if (!minDate || newDate.isSameOrAfter(minDate, 'day')) {
               setDate(newDate);
               if (newDate?.isValid() && keyboardInputString !== undefined) {
                  onChange(format ? moment(newDate).format(format) : newDate, name);
                  setInternalError(null);
               }
            } else {
               setInternalError(`Enter a date after ${minDate.format(DATE_FORMAT_KEYBOARD)}.`);
               onError?.(newDate);
            }
         } else {
            setInternalError(`Enter a date before ${maxDate.format(DATE_FORMAT_KEYBOARD)}.`);
            onError?.(newDate);
         }
      } else if (required) {
         setInternalError('Enter a date.');
         onError?.(newDate);
      } else {
         setDate(null);
         onChange(newDate, name);
         setInternalError(null);
      }
   };

   const handleClear = useCallback(() => {
      setDate(null);
      onChange?.(null, name);
   }, [onChange, name]);

   const textField = clearable ? TextFieldWithClearable : TextFieldFHG;

   return (
      <>
         <DatePicker
            key={'DatePicker ' + refresh}
            name={name}
            ref={ref}
            onAccept={handleDateAccept}
            onChange={ignoreDateChange}
            value={date}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            {...props}
            slotProps={{
               textField: {
                  variant,
                  size: 'small',
                  labelKey,
                  error: error || !!internalError,
                  helperText: helperText || internalError,
                  disabled,
                  clearable,
                  onDateClear: handleClear,
               },
            }}
            slots={{
               textField,
            }}
         />
         <ValidateTarget
            name={'createdDateTime'}
            value={date ? moment(date).isValid() : !required}
            validationMessage={'Enter a valid date.'}
         />
      </>
   );
});

export default DatePickerFHG;
