import ExcelJS from 'exceljs';
import {toNumber} from 'lodash';
import {filter} from 'lodash';
import {isNumber} from 'lodash';
import {isObject} from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import castArray from 'lodash/castArray';
import findIndex from 'lodash/findIndex';
import {removeOne} from './Utils';
import moment from 'moment';
import {DEFAULT_DATE_FORMAT} from '../../Constants';
import map from 'lodash/map';

import {camelCase} from 'lodash';
import values from 'lodash/values';

/**
 * Update the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to update. (e.g. {query, variables, queryPath})
 * @param id of the item to update or the predicate object to find the object to update.
 * @param mutationPathProp Property name for the property to update coming back from the mutation.
 * @return {function: void} The function for update.
 */
export const cacheUpdate = (queryList, id, mutationPathProp) => {
   const useQueryList = castArray(queryList);

   if (id !== undefined) {
      return (proxy, {data}) => {
         for (const queryItem of useQueryList) {
            const {query, variables, queryPath = mutationPathProp, mutationPath = mutationPathProp} = queryItem;
            let resultData = get(data, mutationPath);

            // Did the mutation return any data?
            if (resultData) {
               // Is the data coming back from the mutation an array?
               if (isArray(resultData)) {
                  //If one element use the single element.
                  if (resultData?.length === 1) {
                     resultData = resultData[0];
                  } else {
                     console.log('resultData from the mutation is an array with more than one item.', resultData);
                  }
               }
               try {
                  const cachedData = proxy.readQuery({query, variables});
                  if (cachedData) {
                     const itemIndex = findIndex(cachedData[queryPath], isObject(id) ? id : {id});
                     let arr;

                     if (itemIndex >= 0) {
                        arr = [...cachedData[queryPath]];
                        arr[itemIndex] = resultData;
                     } else {
                        arr = [...(cachedData[queryPath] || []), resultData];
                     }
                     proxy.writeQuery({query, variables, data: {...cachedData, [queryPath]: arr}});
                  } else {
                     if (process.env.NODE_ENV !== 'production') {
                        console.log('Failed to update cache.', variables);
                     }
                  }
               } catch (e) {
                  if (process.env.NODE_ENV !== 'production') {
                     console.log('Failed to update cache.', e);
                  }
               }
            } else {
               console.log('Could not get the result from the mutation data. Check the mutation path', mutationPath);
            }
         }
      };
   } else {
      return cacheAdd(useQueryList, mutationPathProp);
   }
};

/**
 * Add the new item to the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to add the result item. (e.g. {query, variables, queryPath})
 * @param mutationPath Property name resulting object being updated.
 * @param isArray Indicates if the result data to be written back is an array
 * @return {function(*): void} The function to update the cache.
 */
export const cacheAdd = (queryList, mutationPath) => {
   const useQueryList = castArray(queryList);

   return (proxy, {data}) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = mutationPath} = queryItem;
         let newArray;

         const resultData = get(data, mutationPath);
         if (resultData) {
            // Read the data from our cache for this query.
            const cachedData = proxy.readQuery({query, variables});

            if (cachedData) {
               // Write our data back to the cache with the new comment in it
               if (isArray(resultData)) {
                  newArray = [...(cachedData[queryPath] || []), ...resultData];
               } else {
                  newArray = [...(cachedData[queryPath] || []), resultData];
               }
               const newData = {...cachedData, [queryPath]: newArray};
               proxy.writeQuery({query, variables, data: newData});
            } else {
               console.log('cacheAdd - Query not found', query?.definitions?.[0]?.name?.value, variables);
            }
         } else {
            console.log('Could not get the result from the mutation data. Check the mutation path', mutationPath);
         }
      }
   };
};

/**
 * Delete the item add the uuid from the cache for the list of queries. The query list will have the query, the
 * variables, and the queryPath(optional). If the queryPath isn't specified, the path will be used.
 *
 * @param queryList the list of queries to delete the item at uuid. (e.g. {query, variables, queryPath})
 * @param id The id of the item to be deleted.
 * @param path Property name resulting object being updated.
 * @param deleteIdKey The property that holds the id for the deleted object
 * @return {function(*): void} function to update cache for delete.
 */
export const cacheDelete = (queryList, id, path, deleteIdKey = 'id') => {
   const useQueryList = castArray(queryList);

   return (proxy) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = path} = queryItem;

         const cachedData = proxy.readQuery({query, variables});
         if (cachedData) {
            const itemIndex = findIndex(cachedData[queryPath], {[deleteIdKey]: id});
            if (itemIndex >= 0) {
               const modifiedList = removeOne([...cachedData[queryPath]], itemIndex);
               proxy.writeQuery({
                  query,
                  variables,
                  data: {...cachedData, [queryPath]: modifiedList.length > 0 ? modifiedList : null},
               });
            }
         } else {
            console.log('cacheDelete - Query not found', query?.definitions?.[0]?.name?.value, variables);
         }
      }
   };
};

/**
 * Undelete the item add the uuid from the cache for the list of queries. The query list will have the query, the
 * variables, and the queryPath(optional). If the queryPath isn't specified, the path will be used.
 *
 * @param queryList the list of queries to delete the item at uuid. (e.g. {query, variables, queryPath})
 * @param item the item to be undeleted.
 * @param path Property name resulting object being updated.
 * @return {function(*): void} function to update cache for an undelete.
 */
export const cacheUndelete = (queryList, item, path) => {
   const useQueryList = castArray(queryList);

   return (proxy) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = path} = queryItem;

         const cachedData = proxy.readQuery({query, variables});
         if (cachedData) {
            let newData;
            // Force the item to be undeleted because undelete doesn't return the modified item.
            const useItem = {...item, isDeleted: false};

            if (isArray(cachedData[queryPath])) {
               const newArray = [...(cachedData[queryPath] || []), useItem];
               newData = {...cachedData, [queryPath]: newArray};
            } else {
               newData = {[queryPath]: useItem};
            }
            proxy.writeQuery({query, variables, data: newData});
         } else {
            console.log('cacheUndelete - Query not found', query?.definitions?.[0]?.name?.value, variables);
         }
      }
   };
};

export const getDataItem = (data, type) => {
   if (isArray(data?.[type]) && data?.[type]?.length === 1) {
      return data?.[type]?.[0];
   } else {
      return data?.[type];
   }
};

/**
 *
 * @param id lookup ID
 * @param options list of options in which to find the id
 * @param [key] name of field to return.
 * @param [defaultValue] Value if the item and field aren't found
 * @returns {string|*|string} Value of the field of the item if found
 */
export const getLookupValue = (id, options, key = 'name', defaultValue = '') => {
   if (id !== undefined && options?.length > 0) {
      const item = find(options, {id}) || {};
      return item?.[key] || defaultValue;
   }
   return defaultValue;
};

/**
 * Format a date or datetime string. Example default format: '2022-08-03T05:07:20.144Z' will return 08/03/2022.
 *
 * @param {string|Object} dbDateValue The date to format. Can either be a date string or an object with date string at
 *   dbDateValue.value.
 * @param {string} [format] The optional format for the date.
 * @returns {string} The formatted date string.
 */
export function formatDate(dbDateValue, format) {
   let value;

   if (typeof dbDateValue === 'string') {
      value = dbDateValue;
   } else if (dbDateValue && 'value' in dbDateValue) {
      if (dbDateValue.value) {
         value = dbDateValue.value;
      }
   }

   if (value) {
      return moment(value).format(format && typeof format === 'string' ? format : DEFAULT_DATE_FORMAT);
   } else {
      return '';
   }
}

export function formatNumber(dbNumber, options) {
   if (dbNumber?.value) {
      let value = toNumber(dbNumber?.value);
      return isNaN(value) ? '' : new Intl.NumberFormat(undefined, options).format(value);
   } else if (isNumber(dbNumber)) {
      if (dbNumber === -0) {
         return 0;
      }
      return new Intl.NumberFormat(undefined, options).format(dbNumber);
   }
   return '';
}

export function formatCurrency(dbNumber, format) {
   const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
      ...format,
   });

   if (isNumber(dbNumber?.value)) {
      return formatter.format(dbNumber?.value);
   } else if (isNumber(dbNumber)) {
      return formatter.format(dbNumber);
   }
   return '';
}

export function removeEmpty(obj) {
   if (obj) {
      return Object.fromEntries(
         Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v !== 'undefined'),
      );
   }
   return obj;
}

export const exportToExcel = ({
   fileName,
   titleWorksheet,
   tableName,
   columns,
   data,
   orientation = 'portrait',
   totalsRow = false,
   defaultRowHeight = 20,
   defaultColWidth = 12,
}) => {
   const workbook = new ExcelJS.Workbook();

   const worksheet = workbook.addWorksheet(titleWorksheet, {
      views: [{showGridLines: false}],
      pageSetup: {
         orientation,
         fitToPage: true,
         horizontalCentered: true,
         margins: {
            left: 0.15,
            right: 0.1,
            top: 0.75,
            bottom: 0.75,
            header: 0.3,
            footer: 0.3,
         },
      },
   });

   //Add the table header and footer rows.
   worksheet.pageSetup.printArea = `A1:H${data?.length || 20}`;
   worksheet.properties.defaultColWidth = defaultColWidth;
   worksheet.properties.defaultRowHeight = defaultRowHeight;

   createTableLx({
      name: tableName,
      worksheet,
      columns,
      data,
      ref: `A1`,
      totalsRow,
   });
};

function updateCellValue(column, row) {
   let propertyName;

   propertyName = column.columnDef?.meta?.getColumnExportHeader?.() || column.columnDef.accessorKey || column.id;

   let value = row.getValue(column.id) || get(row?.original, propertyName);

   if (column.columnDef?.meta?.getCellExportValue) {
      value = column.columnDef?.meta?.getCellExportValue(value, column);
   }
   return value;
}

function getColumn(column) {
   let header;

   if (column?.columnDef?.meta?.getColumnExportHeader) {
      header = column?.columnDef?.meta?.getColumnExportHeader();
   } else {
      header = column?.columnDef.header;
   }
   return {name: header};
}

/**
 * A table for an Excel spreadsheet.
 *
 * @param name the name of the table. Used internally in the Excel file and doesn't show to the user, but must be unique.
 * @param worksheet The worksheet of the Excel spreadsheet.
 * @param table the table to be exported. Uses table columns and data to generate the spreadsheet.
 * @param tableProps
 */
export const createTableLx = ({name, worksheet, table, ...tableProps}) => {
   let rowModel = table.getRowModel();
   const tableColumns = table.getAllColumns();

   let rows = map(rowModel.rows, (row) => {
      let modifiedItem = [];

      for (const column of tableColumns) {
         if (column.columnDef?.meta?.isExported !== false) {
            modifiedItem.push(updateCellValue(column, row));
         }
      }
      return modifiedItem;
   });
   const useColumns = map(
      filter(tableColumns, (column) => column.columnDef?.meta?.isExported !== false),
      getColumn,
   );

   worksheet.addTable({
      ...tableProps,
      name: camelCase(name),
      columns: useColumns,
      rows,
   });
   worksheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column['eachCell']({includeEmpty: true}, function (cell) {
         var columnLength = cell.value ? cell.value.toString().length : 12;
         if (columnLength > maxLength) {
            maxLength = columnLength;
         }
      });
      column.width = maxLength < 12 ? 12 : maxLength;
   });
};
