// eslint-disable-next-line
const awsmobile = {
   aws_project_region: 'us-east-2',
   aws_cognito_identity_pool_id: 'us-east-2:6aa693a8-f418-4ee1-9f35-0d72f36e9974',
   aws_cognito_region: 'us-east-2',
   aws_user_pools_id: 'us-east-2_Z9dqPDWtW',
   // eslint-disable-next-line
   aws_user_pools_web_client_id: '5l301q4i7uqf8qi6n9dcgv6tro',
};
export default awsmobile;
