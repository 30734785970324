import {Auth} from '@aws-amplify/auth';
import {lazy} from 'react';
import React from 'react';
import './index.css';
import App from './App';
import {LOGO} from './Constants';
import Loading from './fhg/components/Loading';
import SuspenseLx from './fhg/components/SuspenseLx';
import reportWebVitals from './reportWebVitals';
import {RetryLink} from '@apollo/client/link/retry';
import {setContext} from '@apollo/client/link/context';
import {HttpLink, ApolloClient, InMemoryCache, ApolloLink, ApolloProvider} from '@apollo/client';
import {ENDPOINT} from './Constants';
import QueueLink from 'apollo-link-queue';
import {createRoot} from 'react-dom/client';
import {offsetLimitPagination} from '@apollo/client/utilities';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'; //Note: this is TanStack React Query V5

const RecoilRoot = lazy(() => import('./packageExports/RecoilRoot'));
const BrowserRouter = lazy(() => import('./packageExports/BrowserRouter'));
const ThemeProvider = lazy(() => import('./components/ThemeProvider'));

const queryClient = new QueryClient();

const getAccessToken = () => {
   return Auth.currentSession()
      .then((session) => {
         return session.accessToken.jwtToken;
      })
      .catch((error) => {
         console.log(error);
         throw error;
      });
};

const authLink = setContext(async (_, {headers}) => {
   let token = await getAccessToken();

   return {
      headers: {
         ...headers,
         // eslint-disable-next-line
         accesstoken: token || '',
      },
   };
});

const queueLink = new QueueLink();
window.addEventListener('offline', () => queueLink.close());
window.addEventListener('online', () => queueLink.open());

const client = new ApolloClient({
   link: ApolloLink.from([new RetryLink(), queueLink, authLink, new HttpLink({uri: ENDPOINT})]),
   cache: new InMemoryCache({
      typePolicies: {
         Query: {
            fields: {
               deal_AllWhere: {
                  ...offsetLimitPagination(['dealSearch', 'bookFormat']),
                  //Cache holds the entire list of Return from cache the .
                  read(existing, {args: {offset = 0, limit = existing?.length}}) {
                     return existing && existing.slice(offset, offset + limit);
                  },
               },
            },
         },
      },
   }),
});

// Add the format command for adding parameters to strings. For Example:
//    'This is a test: {testName}'.format({testName: 'Test Hello World'})
if (!String.prototype.format) {
   // eslint-disable-next-line
   String.prototype.format = function (values) {
      return this.replace(/{(\w+)}/g, function (match, key) {
         return typeof values[key] !== 'undefined' ? values[key] : match;
      });
   };
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab='home' />);

let img = new Image();
img.src = LOGO;

root.render(
   <React.StrictMode>
      <SuspenseLx fallback={<Loading isLoading />}>
         <QueryClientProvider client={queryClient}>
            <RecoilRoot>
               <ApolloProvider client={client}>
                  <BrowserRouter>
                     <ThemeProvider>
                        <App />
                     </ThemeProvider>
                  </BrowserRouter>
               </ApolloProvider>
            </RecoilRoot>
         </QueryClientProvider>
      </SuspenseLx>
   </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
