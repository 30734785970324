import gql from 'graphql-tag';
import {PAPERWORK_TYPE} from '../Constants';
import {PHONE_TYPE} from '../Constants';
import {SOCIAL_TYPE} from '../Constants';
import {CONTACT_TYPE} from '../Constants';
import {UPDATE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {UNDELETE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {DELETE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {CREATE_UPDATE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {requeryState} from '../fhg/hooks/data/useQueryFHG';
import {FEP_ADDRESS_FRAGMENT} from './FragmentsGL';
import {SDR_REPORT_STATUS_FRAGMENT} from './FragmentsGL';
import {SDR_LOOKUP_FRAGMENT} from './FragmentsGL';
import {CASH_ACTIVITY_FRAGMENT, LAST_PRICE_LOOKUP_FRAGMENT} from './FragmentsGL';
import {SETTLEMENT_PRICE_HISTORY_FRAGMENT} from './FragmentsGL';
import {DEAL_REPORT_FRAGMENT} from './FragmentsGL';
import {STATEMENT_HISTORY_FRAGMENT} from './FragmentsGL';
import {POSITION_REPORT_GROUP_FRAGMENT} from './FragmentsGL';
import {POSITION_REPORT_FRAGMENT} from './FragmentsGL';
import {DEAL_NAME_FRAGMENT} from './FragmentsGL';
import {DEAL_FRAGMENT} from './FragmentsGL';
import {USER_FRAGMENT} from './FragmentsGL';
import {COMMODITY_FRAGMENT} from './FragmentsGL';
import {COMMODITY_NAME_FRAGMENT} from './FragmentsGL';
import {TYPE_FRAGMENT} from './FragmentsGL';
import {LOCATION_NAME_FRAGMENT} from './FragmentsGL';
import {LOCATION_FRAGMENT} from './FragmentsGL';
import {CLIENT_SOCIAL_FRAGMENT} from './FragmentsGL';
import {CONTACT_SOCIAL_FRAGMENT} from './FragmentsGL';
import {PHONE_FRAGMENT} from './FragmentsGL';
import {STATE_FRAGMENT} from './FragmentsGL';
import {CITY_FRAGMENT} from './FragmentsGL';
import {CLIENT_CONTACT_FRAGMENT} from './FragmentsGL';
import {CLIENT_FRAGMENT} from './FragmentsGL';

// CLIENT
// export const CLIENT_ALL_QUERY = gql`
//    query getAllClients {
//       clients: client_All {
//          ...clientInfo
//       }
//    }
//    ${CLIENT_FRAGMENT}
// `;

export const CLIENT_ALL_NAME_QUERY = gql`
   query getAllClientNames {
      clients: client_All {
         id
         name
         label: name
      }
   }
`;

export const CLIENT_BY_ID_QUERY = gql`
   query getClientById($clientId: UUID!) {
      client: client_ById(clientId: $clientId) {
         ...clientInfo
      }
   }
   ${CLIENT_FRAGMENT}
`;

export const CLIENT_BY_ID_FLASHPOINT_CONTACT_QUERY = gql`
   query getClientByIdFlashpointContact($clientId: UUID!) {
      client: client_ById(clientId: $clientId) {
         id
         flashpointContactId
      }
   }
`;

export const LOCATION_BY_ID_QUERY = gql`
   query getLocationById($id: UUID!) {
      location: location_ById(locationId: $id) {
         ...locationInfo
      }
   }
   ${LOCATION_FRAGMENT}
`;

export const CLIENT_CREATE_UPDATE = {
   mutation: gql`
      mutation clientCreateUpdate(
         $id: UUID!
         $cityId: UUID
         $stateId: UUID
         $name: String
         $addressLineOne: String
         $addressLineTwo: String
         $zipCode: String
         $c3AccountId: String
         $accountId: String
         $reportFrequency: String
         $showSubtotals: Boolean
         $website: String
         $paperworkTypeId: UUID
         $w9StatusId: UUID
         $hasFinancials: Boolean
         $fein: String
         $lei: String
         $kycCompleted: Boolean
         $gtcSentDate: DateOnly
         $flashpointContactId: UUID
         $locationIdList: [UUID]
         $sliding: Boolean
         $confidence: Float
         $varModel: String
         $showOptionPremiums: Boolean
         $optionPremiumsStartDate: DateOnly
         $sdrExcluded: Boolean
      ) {
         client: client_CreateUpdate(
            client: {
               id: $id
               cityId: $cityId
               stateId: $stateId
               name: $name
               addressLineOne: $addressLineOne
               addressLineTwo: $addressLineTwo
               zipCode: $zipCode
               accountId: $accountId
               c3AccountId: $c3AccountId
               reportFrequency: $reportFrequency
               website: $website
               paperworkTypeId: $paperworkTypeId
               w9StatusId: $w9StatusId
               hasFinancials: $hasFinancials
               fein: $fein
               lei: $lei
               kycCompleted: $kycCompleted
               gtcSentDate: $gtcSentDate
               flashpointContactId: $flashpointContactId
               locationIdList: $locationIdList
               sliding: $sliding
               confidence: $confidence
               varModel: $varModel
               showSubtotals: $showSubtotals
               showOptionPremiums: $showOptionPremiums
               optionPremiumsStartDate: $optionPremiumsStartDate
               sdrExcluded: $sdrExcluded
            }
         ) {
            ...clientInfo
         }
      }
      ${CLIENT_FRAGMENT}
   `,
   typeKey: 'client.type',
   actionKey: CREATE_UPDATE_ACTION,
};

// Delete the client on the server.
export const CLIENT_DELETE = {
   mutation: gql`
      mutation ClientDelete($clientId: UUID!) {
         client_Delete(clientId: $clientId)
      }
   `,
   typeKey: 'client.type',
   actionKey: DELETE_ACTION,
};

export const getClientCacheQueries = () => {
   return [{query: CLIENT_ALL_NAME_QUERY, queryPath: 'clients'}];
};

export const CLIENT_CONTACT_CREATE_UPDATE = {
   mutation: gql`
      mutation clientContactCreateUpdate(
         $id: UUID!
         $clientId: UUID
         $contactTypeId: UUID
         $name: String
         $email: String
         $isPrimary: Boolean
         $hasUserAccount: Boolean
      ) {
         clientContact: clientContact_CreateUpdate(
            clientContact: {
               id: $id
               clientId: $clientId
               contactTypeId: $contactTypeId
               name: $name
               email: $email
               isPrimary: $isPrimary
               hasUserAccount: $hasUserAccount
            }
         ) {
            ...clientContactInfo
         }
      }
      ${CLIENT_CONTACT_FRAGMENT}
   `,
   typeKey: 'contact.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const CLIENT_CONTACT_DELETE = {
   mutation: gql`
      mutation clientContactDelete($id: UUID!) {
         clientContact_Delete(clientContactId: $id)
      }
   `,
   typeKey: 'clientContact.type',
   actionKey: DELETE_ACTION,
};

export const CITY_ALL_QUERY = gql`
   query getCityAll {
      cities: city_All {
         ...CityInfo
      }
   }
   ${CITY_FRAGMENT}
`;

export const CITY_BY_ID_QUERY = gql`
   query getCityById($cityId: UUID!) {
      city: city_ById(cityId: $cityId) {
         ...CityInfo
      }
   }
   ${CITY_FRAGMENT}
`;

export const CITY_CREATE_UPDATE = {
   mutation: gql`
      mutation cityCreateUpdate($id: UUID!, $name: String) {
         city: city_CreateUpdate(city: {id: $id, name: $name}) {
            ...CityInfo
         }
      }
      ${CITY_FRAGMENT}
   `,
   typeKey: 'city.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const getCityCacheQueries = () => {
   return [{query: CITY_ALL_QUERY, queryPath: 'cities'}];
};

export const STATE_ALL_QUERY = gql`
   query getStateAll {
      states: state_All {
         ...StateInfo
      }
   }
   ${STATE_FRAGMENT}
`;

export const STATE_BY_ID_QUERY = gql`
   query getStateById($stateId: UUID!) {
      state: state_ById(stateId: $stateId) {
         ...StateInfo
      }
   }
   ${STATE_FRAGMENT}
`;

export const CLIENT_CONTACT_ALL_QUERY = gql`
   query getAllClientContact($clientId: [UUID], $email: [String]) {
      contacts: clientContact_AllWhere(clientContactSearch: {clientId: $clientId, email: $email}) {
         ...clientContactInfo
      }
   }
   ${CLIENT_CONTACT_FRAGMENT}
`;

export const CONTACT_CLIENT_BY_ID_QUERY = gql`
   query getClientContactById($contactId: UUID!) {
      contact: clientContact_ById(clientContactId: $contactId) {
         ...clientContactInfo
      }
   }
   ${CLIENT_CONTACT_FRAGMENT}
`;

export const getClientContactCacheQueries = (clientId) => {
   return [{query: CLIENT_CONTACT_ALL_QUERY, variables: {clientId}, queryPath: 'contacts'}];
};

// export const FLASHPOINT_CONTACT_ALL_QUERY = gql`
//    query getAllFlashpointContact {
//       contacts: flashpointContact_All {
//          ...flashpointContactInfo
//       }
//    }
//    ${FLASHPOINT_CONTACT_FRAGMENT}
// `;
//
// export const CONTACT_FLASHPOINT_BY_ID_QUERY = gql`
//    query getFlashpointContactById($contactId: UUID!) {
//       contact: flashpointContact_ById(flashpointContactId: $contactId) {
//          ...flashpointContactInfo
//       }
//    }
//    ${FLASHPOINT_CONTACT_FRAGMENT}
// `;
//
// export const FLASHPOINT_CONTACT_CREATE_UPDATE = {
//    mutation: gql`
//       mutation flashpointContactCreateUpdate($id: UUID!, $name: String, $email: String, $phone: String) {
//          flashpointContact: flashpointContact_CreateUpdate(
//             flashpointContact: {id: $id, name: $name, email: $email, phone: $phone}
//          ) {
//             ...flashpointContactInfo
//          }
//       }
//       ${FLASHPOINT_CONTACT_FRAGMENT}
//    `,
//    typeKey: 'contact.type',
//    actionKey: CREATE_UPDATE_ACTION,
// };
//
// export const FLASHPOINT_CONTACT_DELETE = {
//    mutation: gql`
//       mutation flashpointContactDelete($id: UUID!) {
//          flashpointContact_Delete(flashpointContactId: $id)
//       }
//    `,
//    typeKey: 'flashpointContact.type',
//    actionKey: DELETE_ACTION,
// };
//
// export const getFlashpointContactCacheQueries = () => {
//    return [{query: FLASHPOINT_CONTACT_ALL_QUERY, undefined, queryPath: 'contacts'}];
// };

// const CLIENT_SOCIAL_BY_ID_QUERY = gql`
//    query getClientSocialById($clientSocialId: UUID!) {
//       clientSocial: clientSocial_ById(clientSocialId: $clientSocialId) {
//          ...clientSocialInfo
//       }
//    }
//    ${CLIENT_SOCIAL_FRAGMENT}
// `;

export const PHONE_ALL_WHERE_QUERY = gql`
   query getContactPhoneAllWhere($clientContactId: [UUID]) {
      phone: contactPhone_AllWhere(contactPhoneSearch: {clientContactId: $clientContactId}) {
         ...phoneInfo
      }
   }
   ${PHONE_FRAGMENT}
`;

// export const PHONE_BY_ID_QUERY = gql`
//    query getContactPhoneById($contactPhoneId: UUID!) {
//       phone: contactPhone_ById(contactPhoneId: $contactPhoneId) {
//          ...phoneInfo
//       }
//    }
//    ${PHONE_FRAGMENT}
// `;

export const CONTACT_PHONE_CREATE_UPDATE = {
   mutation: gql`
      mutation contactPhoneCreateUpdate(
         $id: UUID!
         $clientContactId: UUID
         $phone: String
         $phoneType: String
         $phoneTypeId: UUID
      ) {
         contactPhone: contactPhone_CreateUpdate(
            contactPhone: {
               id: $id
               clientContactId: $clientContactId
               phone: $phone
               phoneType: $phoneType
               phoneTypeId: $phoneTypeId
            }
         ) {
            ...phoneInfo
         }
      }
      ${PHONE_FRAGMENT}
   `,
   typeKey: 'contact.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const CONTACT_PHONE_DELETE = {
   mutation: gql`
      mutation contactPhoneDelete($id: UUID!) {
         contactPhone_Delete(contactPhoneId: $id)
      }
   `,
   typeKey: 'phone.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const getPhoneCacheQueries = (clientContactId) => {
   return [{query: PHONE_ALL_WHERE_QUERY, variables: {clientContactId}, queryPath: 'phone'}];
};

export const SOCIAL_ALL_WHERE_QUERY = gql`
   query getContactSocialAllWhere($clientContactId: [UUID]) {
      social: contactSocial_AllWhere(contactSocialSearch: {clientContactId: $clientContactId}) {
         ...contactSocialInfo
      }
   }
   ${CONTACT_SOCIAL_FRAGMENT}
`;

export const CONTACT_SOCIAL_CREATE_UPDATE = {
   mutation: gql`
      mutation contactSocialCreateUpdate(
         $id: UUID!
         $clientContactId: UUID
         $name: String
         $socialTypeId: UUID
         $url: String
      ) {
         contactSocial: contactSocial_CreateUpdate(
            contactSocial: {
               id: $id
               clientContactId: $clientContactId
               name: $name
               socialTypeId: $socialTypeId
               url: $url
            }
         ) {
            ...contactSocialInfo
         }
      }
      ${CONTACT_SOCIAL_FRAGMENT}
   `,
   typeKey: 'social.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const CONTACT_SOCIAL_DELETE = {
   mutation: gql`
      mutation contactSocialDelete($id: UUID!) {
         contactSocial_Delete(contactSocialId: $id)
      }
   `,
   typeKey: 'social.type',
   actionKey: DELETE_ACTION,
};

export const getSocialCacheQueries = (clientContactId) => {
   return [{query: SOCIAL_ALL_WHERE_QUERY, variables: {clientContactId}, queryPath: 'social'}];
};

export const CLIENT_SOCIAL_ALL_WHERE_QUERY = gql`
   query getClientSocialAllWhere($clientId: [UUID]) {
      social: clientSocial_AllWhere(clientSocialSearch: {clientId: $clientId}) {
         ...clientSocialInfo
      }
   }
   ${CLIENT_SOCIAL_FRAGMENT}
`;

export const CLIENT_SOCIAL_CREATE_UPDATE = {
   mutation: gql`
      mutation clientSocialCreateUpdate(
         $id: UUID!
         $clientId: UUID!
         $name: String
         $socialTypeId: UUID
         $url: String
      ) {
         clientSocial: clientSocial_CreateUpdate(
            clientSocial: {id: $id, clientId: $clientId, name: $name, socialTypeId: $socialTypeId, url: $url}
         ) {
            ...clientSocialInfo
         }
      }
      ${CLIENT_SOCIAL_FRAGMENT}
   `,
   typeKey: 'social.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const CLIENT_SOCIAL_DELETE = {
   mutation: gql`
      mutation clientSocialDelete($id: UUID!) {
         clientSocial_Delete(clientSocialId: $id)
      }
   `,
   typeKey: 'social.type',
   actionKey: DELETE_ACTION,
};

export const getClientSocialCacheQueries = (clientId) => {
   return [{query: CLIENT_SOCIAL_ALL_WHERE_QUERY, variables: {clientId}, queryPath: 'social'}];
};

export const TYPE_ALL_QUERY = gql`
   query getTypeAll {
      types: type_All {
         id
         category
         name
         label: name
      }
   }
`;

export const TYPE_ALL_WHERE_QUERY = gql`
   query getTypeWhereAll($id: [UUID], $category: [String]) {
      types: type_AllWhere(typeSearch: {id: $id, category: $category}) {
         id
         category
         name
         label: name
      }
   }
`;

export const TYPE_CREATE_UPDATE = {
   mutation: gql`
      mutation typeCreateUpdate($id: UUID!, $category: String, $name: String) {
         type: type_CreateUpdate(type: {id: $id, category: $category, name: $name}) {
            ...typeInfo
         }
      }
      ${TYPE_FRAGMENT}
   `,
   typeKey: 'type.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const TYPE_DELETE = {
   mutation: gql`
      mutation typeDelete($typeId: UUID!) {
         type_Delete(typeId: $typeId)
      }
   `,
   typeKey: 'type.type',
   actionKey: DELETE_ACTION,
};

export const getTypeCacheQueries = () => {
   return [
      {
         query: TYPE_ALL_QUERY,
         queryPath: 'types',
      },
      {
         query: TYPE_ALL_WHERE_QUERY,
         variables: {category: [CONTACT_TYPE, SOCIAL_TYPE, PHONE_TYPE]},
         queryPath: 'types',
      },
      {
         query: TYPE_ALL_WHERE_QUERY,
         variables: {category: [PAPERWORK_TYPE]},
         queryPath: 'types',
      },
      {
         query: TYPE_ALL_WHERE_QUERY,
         variables: {category: [SOCIAL_TYPE]},
         queryPath: 'types',
      },
   ];
};

export const W_9_STATUS_ALL_QUERY = gql`
   query getW9StatusAll {
      w9Status: w9Status_All {
         id
         name
      }
   }
`;

// export const LOCATION_ALL_QUERY = gql`
//    query getLocationAll {
//       locations: location_All {
//          ...locationInfo
//       }
//    }
//    ${LOCATION_FRAGMENT}
// `;
//
export const LOCATION_NAME_ALL_QUERY = gql`
   query getLocationNameAll {
      locations: location_All {
         ...locationNameInfo
      }
   }
   ${LOCATION_NAME_FRAGMENT}
`;

export const getLocationCacheQueries = () => {
   return [{query: LOCATION_NAME_ALL_QUERY, queryPath: 'locations'}];
};

export const LOCATION_CREATE_UPDATE = {
   mutation: gql`
      mutation locationCreateUpdate(
         $id: UUID!
         $addressLineOne: String
         $addressLineTwo: String
         $cityId: UUID
         $stateId: UUID
         $zipCode: String
         $direction: String
         $name: String
         $physDeliveryMethodId: UUID
         $lat: Float
         $lng: Float
      ) {
         location: location_CreateUpdate(
            location: {
               id: $id
               addressLineOne: $addressLineOne
               addressLineTwo: $addressLineTwo
               cityId: $cityId
               stateId: $stateId
               direction: $direction
               name: $name
               zipCode: $zipCode
               physDeliveryMethodId: $physDeliveryMethodId
               lat: $lat
               lng: $lng
            }
         ) {
            ...locationInfo
         }
      }
      ${LOCATION_FRAGMENT}
   `,
   typeKey: 'location.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const LOCATION_DELETE = {
   mutation: gql`
      mutation locationDelete($id: UUID!) {
         location_Delete(locationId: $id)
      }
   `,
   typeKey: 'location.type',
   actionKey: DELETE_ACTION,
};

export const LOCATION_UNDELETE = {
   mutation: gql`
      mutation locationUndelete($id: UUID!) {
         location_UnDelete(locationId: $id)
      }
   `,
   typeKey: 'location.type',
   actionKey: UNDELETE_ACTION,
};

export const COMMODITY_NAME_ALL_QUERY = gql`
   query getCommodityNameAll {
      commodities: commodity_All {
         ...commodityNameInfo
      }
   }
   ${COMMODITY_NAME_FRAGMENT}
`;

export const COMMODITY_ALL_QUERY = gql`
   query getCommodityNameAll {
      commodities: commodity_All {
         ...commodityInfo
      }
   }
   ${COMMODITY_FRAGMENT}
`;

export const COMMODITY_ALL_WHERE_QUERY = gql`
   query getCommoditiesAllWhere($name: [String], $isDeleted: [Boolean]) {
      commodities: commodity_AllWhere(commoditySearch: {name: $name, isDeleted: $isDeleted}) {
         ...commodityInfo
      }
   }
   ${COMMODITY_FRAGMENT}
`;

export const getCommodityCacheQueries = (id) => {
   return [
      {query: COMMODITY_ALL_QUERY, queryPath: 'commodities'},
      {query: COMMODITY_NAME_ALL_QUERY, queryPath: 'commodities'},
      {query: COMMODITY_BY_ID_QUERY, variables: {id}, queryPath: 'commodity'},
   ];
};

export const COMMODITY_CREATE_UPDATE = {
   mutation: gql`
      mutation commodityCreateUpdate(
         $commodityTypeId: UUID
         $euroAsianCode: String
         $exchange: String
         $exchangeTypeId: UUID
         $expireSetting: CommodityExpireOption
         $futOptCode: String
         $id: UUID!
         $measurement: String
         $measurementId: UUID
         $name: String
         $note: String
         $psCode: String
         $psCodeTypeId: UUID
         $sdrExcluded: Boolean
         $underlying: String
         $underlyingTypeId: UUID
      ) {
         commodity: commodity_CreateUpdate(
            commodity: {
               commodityTypeId: $commodityTypeId
               euroAsianCode: $euroAsianCode
               exchange: $exchange
               exchangeTypeId: $exchangeTypeId
               expireSetting: $expireSetting
               futOptCode: $futOptCode
               id: $id
               measurement: $measurement
               measurementId: $measurementId
               name: $name
               note: $note
               psCode: $psCode
               psCodeTypeId: $psCodeTypeId
               sdrExcluded: $sdrExcluded
               underlying: $underlying
               underlyingTypeId: $underlyingTypeId
            }
         ) {
            ...commodityInfo
         }
      }
      ${COMMODITY_FRAGMENT}
   `,
   typeKey: 'commodity.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const COMMODITY_DELETE = {
   mutation: gql`
      mutation commodityDelete($id: UUID!) {
         commodity_Delete(commodityId: $id)
      }
   `,
   typeKey: 'commodity.type',
   actionKey: DELETE_ACTION,
};

export const COMMODITY_UNDELETE = {
   mutation: gql`
      mutation commodityUndelete($id: UUID!) {
         commodity_UnDelete(commodityId: $id)
      }
   `,
   typeKey: 'commodity.type',
   actionKey: UNDELETE_ACTION,
};

export const COMMODITY_BY_ID_QUERY = gql`
   query getCommodityById($id: UUID!) {
      commodity: commodity_ById(commodityId: $id) {
         ...commodityInfo
      }
   }
   ${COMMODITY_FRAGMENT}
`;

// USERS
export const USER_ALL_QUERY = gql`
   query getAllUser($includeDeleted: Boolean) {
      users: user_All(includeDeleted: $includeDeleted) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

export const USERS_ALL_WHERE_QUERY = gql`
   query getUsersAllWhere($contactName: [String], $username: [String], $cognitoSub: [String], $isDeleted: [Boolean]) {
      users: user_AllWhere(
         userSearch: {contactName: $contactName, username: $username, cognitoSub: $cognitoSub, isDeleted: $isDeleted}
      ) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

export const USER_BY_ID_QUERY = gql`
   query getUserById($id: UUID!) {
      user: user_ById(userId: $id) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

export const USER_CREATE_UPDATE = {
   mutation: gql`
      mutation userCreateUpdate(
         $id: UUID!
         $email: String
         $username: String
         $contactName: String
         $password: String
      ) {
         user: user_CreateUpdate(
            user: {id: $id, email: $email, username: $username, contactName: $contactName, password: $password}
         ) {
            ...userInfo
         }
      }
      ${USER_FRAGMENT}
   `,
   typeKey: 'user.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const USER_DELETE = {
   mutation: gql`
      mutation userDelete($id: UUID!) {
         user_Delete(userId: $id)
      }
   `,
   typeKey: 'user.type',
   actionKey: DELETE_ACTION,
};

export const USER_UNDELETE = {
   mutation: gql`
      mutation userUndelete($id: UUID!) {
         user_UnDelete(userId: $id)
      }
   `,
   typeKey: 'user.type',
   actionKey: UNDELETE_ACTION,
};

export const getUserCacheQueries = () => {
   return [{query: USER_ALL_QUERY, queryPath: 'users'}];
};

// BOOKING
// export const BOOKING_NAME_ALL_QUERY = gql`
//    query getBookingNameAll {
//       bookings: deal_All(bookFormat: true) {
//          ...dealNameInfo
//       }
//    }
//    ${DEAL_NAME_FRAGMENT}
// `;

export const DEAL_GENERAL_NAME_QUERY = gql`
   query getDealGeneral(
      $clientId: [UUID]
      $flashpointContactId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $end: [DateOnly]
      $bookFormat: Boolean
      $limit: Int
      $offset: Int
   ) {
      bookings: deal_General(
         bookFormat: $bookFormat
         limit: $limit
         offset: $offset
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            end: $end
         }
      ) {
         ...dealNameInfo
      }
   }
   ${DEAL_NAME_FRAGMENT}
`;

export const DEAL_GENERAL_COUNT = gql`
   query getDealGeneralCount(
      $clientId: [UUID]
      $flashpointContactId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $start: [DateOnly]
      $end: [DateOnly]
      $bookFormat: Boolean
      $lastPriceIsZero: [Boolean]
      $entryDateStart: DateOnly
      $reportType: String
      $entryDateEnd: DateOnly
   ) {
      count: deal_GeneralCount(
         bookFormat: $bookFormat
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            start: $start
            end: $end
            lastPriceIsZero: $lastPriceIsZero
            reportType: $reportType
            entryDateEnd: $entryDateEnd
            entryDateStart: $entryDateStart
         }
      )
   }
`;

export const DEAL_GENERAL_QUERY = gql`
   query getDealGeneral(
      $bookFormat: Boolean!
      $clientId: [UUID]
      $flashpointContactId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $limit: Int
      $offset: Int
      $statementDate: String
      $start: [DateOnly]
      $end: [DateOnly]
      $lastPriceIsZero: [Boolean]
      $entryDateEnd: DateOnly
      $entryDateStart: DateOnly
      $futOrOpt: [String]
      $sortOrder: [OrderSpec]
   ) {
      bookings: deal_General(
         bookFormat: $bookFormat
         limit: $limit
         offset: $offset
         statementDate: $statementDate
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            futOrOpt: $futOrOpt
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            start: $start
            end: $end
            lastPriceIsZero: $lastPriceIsZero
            entryDateEnd: $entryDateEnd
            entryDateStart: $entryDateStart
         }
         sortOrder: $sortOrder
      ) {
         ...dealInfo
      }
   }
   ${DEAL_FRAGMENT}
`;

export const DEAL_SETTLEMENTS_QUERY = gql`
   query getDealSettlements(
      $bookFormat: Boolean!
      $clientId: [UUID]
      $flashpointContactId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $limit: Int
      $offset: Int
      $statementDate: String
      $start: [DateOnly]
      $end: [DateOnly]
      $lastPriceIsZero: [Boolean]
      $entryDateEnd: DateOnly
      $entryDateStart: DateOnly
      $futOrOpt: [String]
   ) {
      bookings: deal_Settlements(
         bookFormat: $bookFormat
         limit: $limit
         offset: $offset
         statementDate: $statementDate
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            futOrOpt: $futOrOpt
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            start: $start
            end: $end
            lastPriceIsZero: $lastPriceIsZero
            entryDateEnd: $entryDateEnd
            entryDateStart: $entryDateStart
         }
      ) {
         ...dealStatementInfo
      }
   }
   ${DEAL_REPORT_FRAGMENT}
`;

export const DEAL_SETTLEMENTS_NAME_QUERY = gql`
   query getDealSettlements(
      $clientId: [UUID]
      $flashpointContactId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $end: [DateOnly]
      $bookFormat: Boolean
      $limit: Int
      $offset: Int
   ) {
      bookings: deal_Settlements(
         bookFormat: $bookFormat
         limit: $limit
         offset: $offset
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            end: $end
         }
      ) {
         ...dealNameInfo
      }
   }
   ${DEAL_NAME_FRAGMENT}
`;

export const DEAL_SETTLEMENTS_COUNT = gql`
   query getDealSettlementsCount(
      $clientId: [UUID]
      $flashpointContactId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $start: [DateOnly]
      $end: [DateOnly]
      $bookFormat: Boolean
      $lastPriceIsZero: [Boolean]
      $entryDateStart: DateOnly
      $reportType: String
      $entryDateEnd: DateOnly
   ) {
      count: deal_SettlementsCount(
         bookFormat: $bookFormat
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            start: $start
            end: $end
            lastPriceIsZero: $lastPriceIsZero
            reportType: $reportType
            entryDateEnd: $entryDateEnd
            entryDateStart: $entryDateStart
         }
      )
   }
`;

export const DEAL_OPEN_POSITIONS_QUERY = gql`
   query getDealOpenPositions(
      $bookFormat: Boolean!
      $clientId: [UUID]
      $flashpointContactId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $limit: Int
      $offset: Int
      $statementDate: String
      $start: [DateOnly]
      $end: [DateOnly]
      $lastPriceIsZero: [Boolean]
      $entryDateEnd: DateOnly
      $entryDateStart: DateOnly
      $includePhysical: Boolean
      $futOrOpt: [String]
   ) {
      bookings: deal_OpenPositions(
         bookFormat: $bookFormat
         limit: $limit
         offset: $offset
         statementDate: $statementDate
         includePhysical: $includePhysical
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            futOrOpt: $futOrOpt
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            start: $start
            end: $end
            lastPriceIsZero: $lastPriceIsZero
            entryDateEnd: $entryDateEnd
            entryDateStart: $entryDateStart
         }
      ) {
         ...dealStatementInfo
      }
   }
   ${DEAL_REPORT_FRAGMENT}
`;

export const DEAL_NEW_DEALS_QUERY = gql`
   query getDealNewDeals(
      $bookFormat: Boolean!
      $clientId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $limit: Int
      $offset: Int
      $statementDate: String
      $includePhysical: Boolean
      $futOrOpt: [String]
      $reportType: String
      $entryDateEnd: DateOnly
      $entryDateStart: DateOnly
      $newDealsFilter: NewDealsFilter
      $flashpointContactId: [UUID]
   ) {
      bookings: deal_NewDeals(
         bookFormat: $bookFormat
         limit: $limit
         offset: $offset
         statementDate: $statementDate
         includePhysical: $includePhysical
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            futOrOpt: $futOrOpt
            reportType: $reportType
            entryDateEnd: $entryDateEnd
            entryDateStart: $entryDateStart
            newDealsFilter: $newDealsFilter
         }
      ) {
         ...dealStatementInfo
      }
   }
   ${DEAL_REPORT_FRAGMENT}
`;

export const BOOKING_BY_ID_QUERY = gql`
   query getBookingById($id: [UUID]) {
      booking: deal_AllWhere(bookFormat: true, dealSearch: {dealGroupId: $id}) {
         ...dealInfo
      }
   }
   ${DEAL_FRAGMENT}
`;

export const BOOKING_DELETE = {
   mutation: gql`
      mutation bookingDelete($dealGroupId: UUID!) {
         booking_Delete(dealGroupId: $dealGroupId)
      }
   `,
   typeKey: 'booking.type',
   actionKey: DELETE_ACTION,
};

//TODO booking_CreateUpdate returns a single Deal, but can change multiple Deals. This causes a cache problem.
// Until fixed always use refetch and not cacheUpdate.
export const BOOKING_CREATE_UPDATE = {
   mutation: gql`
      mutation bookingCreateUpdate(
         $buyOrSell: String
         $callOrPut: String
         $clientId: UUID
         $cmeMonthCode: String
         $cmeYearCode: String
         $commission: Float
         $commissionRatePerLot: Float
         $commodityId: UUID
         $createdDateTime: Timestamp
         $c3Contract: String
         $volume: Float
         $dealGroupId: UUID!
         $dealTypeId: UUID
         $dealGroupEnd: DateOnly!
         $flashpointContactId: UUID
         $ir: String
         $irFee: String
         $isHidden: Boolean
         $locationId: UUID
         $note: String
         $password: String
         $premium: Float
         $price: Float
         $psMonthCode: String
         $psYearCode: String
         $refMonth: DateOnly
         $dealGroupStart: DateOnly!
         $strike: Float
         $customVolume: [Float]
         $manualLastPrice: Float
         $customExpiration: DateOnly
         $approved: Boolean
      ) {
         booking: booking_CreateUpdate(
            booking: {
               buyOrSell: $buyOrSell
               callOrPut: $callOrPut
               clientId: $clientId
               cmeMonthCode: $cmeMonthCode
               cmeYearCode: $cmeYearCode
               commission: $commission
               commissionRatePerLot: $commissionRatePerLot
               commodityId: $commodityId
               createdDateTime: $createdDateTime
               c3Contract: $c3Contract
               volume: $volume
               dealGroupId: $dealGroupId
               dealTypeId: $dealTypeId
               dealGroupEnd: $dealGroupEnd
               flashpointContactId: $flashpointContactId
               ir: $ir
               irFee: $irFee
               isHidden: $isHidden
               locationId: $locationId
               note: $note
               password: $password
               premium: $premium
               price: $price
               psMonthCode: $psMonthCode
               psYearCode: $psYearCode
               refMonth: $refMonth
               dealGroupStart: $dealGroupStart
               strike: $strike
               customVolume: $customVolume
               manualLastPrice: $manualLastPrice
               customExpiration: $customExpiration
               approved: $approved
            }
         ) {
            ...dealInfo
         }
      }
      ${DEAL_FRAGMENT}
   `,
   typeKey: 'booking.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const BOOKING_UNDELETE = {
   mutation: gql`
      mutation bookingUndelete($id: UUID!) {
         booking_UnDelete(dealGroupId: $id)
      }
   `,
   typeKey: 'booking.type',
   actionKey: UNDELETE_ACTION,
};

// export const getBookingCacheQueries = () => {
//    return [{query: CLIENT_ALL_NAME_QUERY, queryPath: 'clients'}];
// };
//
// DEALS
// export const DEAL_NAME_ALL_QUERY = gql`
//    query getDealNameAll {
//       deals: deal_All {
//          ...dealNameInfo
//       }
//    }
//    ${DEAL_NAME_FRAGMENT}
// `;
//
// export const DEAL_BY_ID_QUERY = gql`
//    query getDealById($dealId: UUID!) {
//       deal: deal_ById(dealId: $dealId) {
//          ...dealInfo
//       }
//    }
//    ${DEAL_FRAGMENT}
// `;
//
// export const DEAL_DELETE = {
//    mutation: gql`
//       mutation dealDelete($id: UUID!) {
//          deal_Delete(dealId: $id)
//       }
//    `,
//    typeKey: 'deal.type',
//    actionKey: DELETE_ACTION,
// };
//
export const DEAL_CREATE_UPDATE = {
   mutation: gql`
      mutation dealCreateUpdate(
         $id: UUID!
         $buyOrSell: String
         $callOrPut: String
         $clientId: UUID
         $cmeMonthCode: String
         $cmeYearCode: String
         $commission: Float
         $commissionRatePerLot: Float
         $commodityId: UUID
         $volume: Float
         $dealGroupId: UUID
         $dealTypeId: UUID
         $dealGroupEnd: DateOnly!
         $dealGroupStart: DateOnly!
         $end: DateOnly
         $flashpointContactId: UUID
         $ir: String
         $irFee: String
         $isHidden: Boolean
         $note: String
         $password: String
         $premium: Float
         $price: Float
         $psMonthCode: String
         $psYearCode: String
         $refMonth: DateOnly
         $start: DateOnly
         $strike: Float
         $underlying: String
         $manualLastPrice: Float
         $approved: Boolean
      ) {
         deal: deal_CreateUpdate(
            deal: {
               id: $id
               buyOrSell: $buyOrSell
               callOrPut: $callOrPut
               clientId: $clientId
               cmeMonthCode: $cmeMonthCode
               cmeYearCode: $cmeYearCode
               commission: $commission
               commissionRatePerLot: $commissionRatePerLot
               commodityId: $commodityId
               volume: $volume
               dealGroupId: $dealGroupId
               dealTypeId: $dealTypeId
               dealGroupEnd: $dealGroupEnd
               dealGroupStart: $dealGroupStart
               end: $end
               flashpointContactId: $flashpointContactId
               ir: $ir
               irFee: $irFee
               isHidden: $isHidden
               note: $note
               password: $password
               premium: $premium
               price: $price
               psMonthCode: $psMonthCode
               psYearCode: $psYearCode
               refMonth: $refMonth
               start: $start
               strike: $strike
               underlying: $underlying
               manualLastPrice: $manualLastPrice
               approved: $approved
            }
         ) {
            ...dealInfo
         }
      }
      ${DEAL_FRAGMENT}
   `,
   typeKey: 'deal.type',
   actionKey: CREATE_UPDATE_ACTION,
};
//
// export const DEAL_UNDELETE = {
//    mutation: gql`
//       mutation dealUndelete($id: UUID!) {
//          deal_UnDelete(dealId: $id)
//       }
//    `,
//    typeKey: 'deal.type',
//    actionKey: UNDELETE_ACTION,
// };

export const getDealCacheQueries = () => {
   // on useQueryFHG the queryPath must be true for this to work.
   const bookingsQueries = requeryState['bookings'] ?? [];
   const bookingQueries = requeryState['booking'] ?? [];
   return [...bookingsQueries, ...bookingQueries];
};

// Position Report
export const POSITION_REPORT_QUERY = gql`
   query positionReport(
      $clientId: [UUID]
      $commodityId: [UUID]
      $commodityTypeId: [UUID]
      $searchEnd: DateOnly
      $searchStart: DateOnly
      $statementDate: DateOnly
   ) {
      positions: report_Positions(
         clientId: $clientId
         searchEnd: $searchEnd
         searchStart: $searchStart
         commodityId: $commodityId
         commodityTypeId: $commodityTypeId
         statementDate: $statementDate
      ) {
         ...positionReportInfo
      }
   }
   ${POSITION_REPORT_FRAGMENT}
`;

// Position Report for Groups
export const POSITION_REPORT_GROUP_QUERY = gql`
   query positionReportGroup(
      $searchEnd: DateOnly
      $searchStart: DateOnly
      $clientId: [UUID]
      $statementDate: DateOnly
   ) {
      positions: report_Positions(
         searchEnd: $searchEnd
         searchStart: $searchStart
         clientId: $clientId
         statementDate: $statementDate
      ) {
         ...positionReportGroupInfo
      }
   }
   ${POSITION_REPORT_GROUP_FRAGMENT}
`;

// Statement
export const STATEMENT_HISTORY_ALL_WHERE_QUERY = gql`
   query getStatementHistoryAllWhere($snapshotDate: [DateOnly], $clientId: [UUID]) {
      statement: statementHistory_AllWhere(statementHistorySearch: {clientId: $clientId, snapshotDate: $snapshotDate}) {
         ...statementHistoryInfo
      }
   }
   ${STATEMENT_HISTORY_FRAGMENT}
`;

export const STATEMENT_HISTORY_CREATE_UPDATE = {
   mutation: gql`
      mutation statementHistoryCreateUpdate(
         $id: UUID!
         $snapshotDate: DateOnly
         $startDate: DateOnly
         $removedDate: DateOnly
         $clientId: UUID!
         $beginningCash: Float
         $cashActivity: Float
         $capitalRequirements: Float
      ) {
         statement: statementHistory_CreateUpdate(
            statementHistory: {
               id: $id
               clientId: $clientId
               startDate: $startDate
               beginningCash: $beginningCash
               cashActivity: $cashActivity
               snapshotDate: $snapshotDate
               removedDate: $removedDate
               capitalRequirements: $capitalRequirements
            }
         ) {
            ...statementHistoryInfo
         }
      }
      ${STATEMENT_HISTORY_FRAGMENT}
   `,
   typeKey: 'statement.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const getStatementCacheQueries = (snapshotDate, clientId) => {
   return [{query: STATEMENT_HISTORY_ALL_WHERE_QUERY, variables: {snapshotDate, clientId}, queryPath: 'statement'}];
};

// Units
export const UNIT_TYPE_ALL_QUERY = gql`
   query getUnitTypeAll {
      unitTypes: unitType_All {
         id
         name
         label: name
         abbreviation
      }
   }
`;

export const DAILY_HEDGING_CURVE_ALL_QUERY = gql`
   query getDailyHedgingCurveAll {
      dailyHedgingCurve: dailyHedgingCurve_All {
         data
      }
   }
`;

export const SETTLEMENT_PRICE_HISTORY_ALL_WHERE_QUERY = gql`
   query getSettlementPriceHistoryAllWhere($id: [UUID], $price: [Float], $commodityId: [UUID], $date: [DateOnly]) {
      settlementPriceHistory: settlementPriceHistory_AllWhere(
         includeDeleted: true
         settlementPriceHistorySearch: {id: $id, price: $price, date: $date, commodityId: $commodityId}
      ) {
         ...settlementPriceHistoryInfo
      }
   }
   ${SETTLEMENT_PRICE_HISTORY_FRAGMENT}
`;

export const SETTLEMENT_PRICE_HISTORY_ALL_WHERE_INITIALIZE_QUERY = gql`
   query getSettlementPriceHistoryAllWhereInitialize(
      $id: [UUID]
      $price: [Float]
      $commodityId: [UUID]
      $searchDate: DateOnly!
   ) {
      settlementPriceHistory: settlementPriceHistory_AllWhereInitialize(
         includeDeleted: true
         searchDate: $searchDate
         settlementPriceHistorySearch: {id: $id, price: $price, commodityId: $commodityId}
      ) {
         ...settlementPriceHistoryInfo
      }
   }
   ${SETTLEMENT_PRICE_HISTORY_FRAGMENT}
`;

export const SETTLEMENT_PRICE_HISTORY_BY_ID_QUERY = gql`
   query getSettlementPriceHistoryById($id: UUID!) {
      settlementPriceHistory: settlementPriceHistory_ById(settlementPriceHistoryId: $id) {
         ...settlementPriceHistoryInfo
      }
   }
   ${SETTLEMENT_PRICE_HISTORY_FRAGMENT}
`;

export const SETTLEMENT_PRICE_HISTORY_CREATE = {
   mutation: gql`
      mutation settlementPriceHistoryCreateUpdate(
         $id: UUID!
         $price: Float
         $date: DateOnly
         $commodityId: UUID
         $marginRequirement: Float
      ) {
         settlementPriceHistory: settlementPriceHistory_CreateUpdate(
            settlementPriceHistory: {
               id: $id
               price: $price
               date: $date
               commodityId: $commodityId
               marginRequirement: $marginRequirement
            }
         ) {
            ...settlementPriceHistoryInfo
         }
      }
      ${SETTLEMENT_PRICE_HISTORY_FRAGMENT}
   `,
   typeKey: 'price.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const SETTLEMENT_PRICE_HISTORY_DELETE = {
   mutation: gql`
      mutation settlementPriceHistoryDelete($id: UUID!) {
         settlementPriceHistory_Delete(settlementPriceHistoryId: $id)
      }
   `,
   typeKey: 'price.type',
   actionKey: DELETE_ACTION,
};

export const SETTLEMENT_PRICE_HISTORY_UNDELETE = {
   mutation: gql`
      mutation settlementPriceHistoryUndelete($id: UUID!) {
         settlementPriceHistory_UnDelete(settlementPriceHistoryId: $id)
      }
   `,
   typeKey: 'price.type',
   actionKey: UNDELETE_ACTION,
};

export const getSettlementPriceHistoryCacheQueries = (id, updateVariables) => {
   return [
      {
         query: SETTLEMENT_PRICE_HISTORY_ALL_WHERE_QUERY,
         variables: updateVariables,
         queryPath: 'settlementPriceHistory',
      },
      // {query: SETTLEMENT_PRICE_HISTORY_BY_ID_QUERY, variables: {id}, queryPath: 'settlementPriceHistory'},
   ];
};

export const GET_LAST_PRICE_BY_COMMODITY_QUERY = gql`
   query getLastPrice_ByCommodityAndDate($commodityIds: [UUID]!, $date: DateOnly!) {
      lastPrice: getLastPrice_ByCommodityAndDate(commodityIds: $commodityIds, date: $date) {
         ...lastPriceLookupInfo
      }
   }
   ${LAST_PRICE_LOOKUP_FRAGMENT}
`;

export const IMPORT_DEAL = {
   mutation: gql`
      mutation DealImport(
         $dealEntryFileLocation: FileS3Data!
         $clientStatementsFileLocation: FileS3Data
         $importType: String = "validate"
      ) {
         deal_Import(
            dealEntryFileLocation: $dealEntryFileLocation
            clientStatementsFileLocation: $clientStatementsFileLocation
            importType: $importType
         ) {
            added
         }
      }
   `,
   typeKey: 'deal.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const GET_LIST_CASH_ACTIVITY = gql`
   query getAllCashActivity(
      $isDeleted: [Boolean]
      $clientId: [UUID]
      $dateFrom: DateOnly
      $dateTo: DateOnly
      $type: [CashActivityTypeEnum]
      $limit: Int
      $offset: Int
      $includeDeleted: Boolean
   ) {
      cashes: cashActivity_AllWhere(
         cashActivitySearch: {
            dateTo: $dateTo
            dateFrom: $dateFrom
            isDeleted: $isDeleted
            clientId: $clientId
            type: $type
         }
         limit: $limit
         offset: $offset
         includeDeleted: $includeDeleted
      ) {
         ...cashActivity
      }
   }
   ${CASH_ACTIVITY_FRAGMENT}
`;

export const getListCashRefetchQueries = (variables) => {
   return [{query: CASH_ACTIVITY_ACTIVE_CLIENTS, variables}];
};

export const getListCashesCacheQueries = (variables) => {
   return [{query: GET_LIST_CASH_ACTIVITY, queryPath: 'cashes', variables}];
};

export const ADD_CASH_ACTIVITY = {
   mutation: gql`
      mutation addCashActivity($cashActivity: CashActivityCreateInput!) {
         cash: cashActivity_Create(cashActivity: $cashActivity) {
            ...cashActivity
         }
      }
      ${CASH_ACTIVITY_FRAGMENT}
   `,
   typeKey: 'cash.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const UPDATE_CASH_ACTIVITY = {
   mutation: gql`
      mutation updateCashActivity($cashActivityId: UUID!, $cashActivity: CashActivityUpdateInput!) {
         cash: cashActivity_Update(cashActivityId: $cashActivityId, cashActivity: $cashActivity) {
            ...cashActivity
         }
      }
      ${CASH_ACTIVITY_FRAGMENT}
   `,
   typeKey: 'cash.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const DELETE_CASH_ACTIVITY = {
   mutation: gql`
      mutation deleteCashActivity($cashActivityId: UUID!) {
         cashActivity_Delete(cashActivityId: $cashActivityId)
      }
   `,
   typeKey: 'cash.type',
   actionKey: DELETE_ACTION,
};

export const GET_COUNT_CASH_ACTIVITY = gql`
   query getCountCashActivity($includeDeleted: Boolean) {
      cashes: cashActivity_Count(includeDeleted: $includeDeleted)
   }
`;

export const GET_CASH_ACTIVITY = gql`
   query getCashActivity($cashActivityId: UUID!) {
      cash: cashActivity_ById(cashActivityId: $cashActivityId) {
         ...cashActivity
      }
   }
   ${CASH_ACTIVITY_FRAGMENT}
`;

export const GET_CASH_ACTIVITY_BY_CLIENT = gql`
   query getCashByClientId($clientId: UUID!, $dateFrom: DateOnly, $dateTo: DateOnly, $includeDeleted: Boolean) {
      cashes: cashActivity_ByClient(
         clientId: $clientId
         dateFrom: $dateFrom
         dateTo: $dateTo
         includeDeleted: $includeDeleted
      ) {
         beginningCashBalance
         psTotal
         cashActivityTotal
         optionPremiumTotal
         total
         cashActivities {
            id
            amount
            type
         }
      }
   }
`;

// export const GET_DEAL_CLIENT_SUBTOTAL = gql`
//    query deal_ClientSubtotals($dealSearch: DealSearchInput, $bookFormat: Boolean, $statementDate: String) {
//       cashes: deal_ClientSubtotals(dealSearch: $dealSearch, bookFormat: $bookFormat, statementDate: $statementDate) {
//          subtotal {
//             marketValue
//             tradePrice
//             deltaPosition
//             long
//             short
//             buy
//             sell
//             optionPremium
//          }
//          subtotalsList {
//             marketValue
//             tradePrice
//             deltaPosition
//             long
//             short
//             buy
//             sell
//          }
//          deals {
//             id
//             clientId
//          }
//       }
//    }
// `;

export const CHECK_DEAL_EXPIRING_ALL_WHERE_QUERY = gql`
   query checkExpiringDealReportAllWhere(
      $bookFormat: Boolean!
      $clientId: [UUID]
      $flashpointContactId: [UUID]
      $commodityId: [UUID]
      $dealTypeId: [UUID]
      $searchStart: DateOnly
      $searchEnd: DateOnly
      $limit: Int
      $offset: Int
      $statementDate: String
      $includePhysical: Boolean
      $futOrOpt: [String]
      $reportType: String
      $entryDateEnd: DateOnly
      $entryDateStart: DateOnly
      $newDealsFilter: NewDealsFilter
   ) {
      isShowPS: deal_CheckExpiring(
         bookFormat: $bookFormat
         limit: $limit
         offset: $offset
         statementDate: $statementDate
         includePhysical: $includePhysical
         dealSearch: {
            clientId: $clientId
            flashpointContactId: $flashpointContactId
            commodityId: $commodityId
            dealTypeId: $dealTypeId
            searchStart: $searchStart
            searchEnd: $searchEnd
            futOrOpt: $futOrOpt
            reportType: $reportType
            entryDateEnd: $entryDateEnd
            entryDateStart: $entryDateStart
            newDealsFilter: $newDealsFilter
         }
      )
   }
`;

export const USER_DISABLE2FA = {
   mutation: gql`
      mutation userDisable2FA($userId: UUID!) {
         user_Disable2FA(userId: $userId)
      }
   `,
   typeKey: 'user.type',
   actionKey: DELETE_ACTION,
};
//    deal_SetLockPassword(currentPassword: String, newPassword: String): Boolean

// export const DEAL_SET_LOCK_PASSWORD = {
//    mutation: gql`
//     mutation deal_SetLockPassword($currentPassword: String, $newPassword: String) {
//       deal_SetLockPassword(currentPassword: $currentPassword, newPassword: $newPassword)
// `,
//    typeKey: 'password.type',
//    actionKey: UPDATE_ACTION,
// };

export const DEAL_SET_LOCK_PASSWORD = {
   mutation: gql`
      mutation setDealLockPassword($currentPassword: String, $password: String) {
         deal_SetLockPassword(currentPassword: $currentPassword, newPassword: $password)
      }
   `,
   typeKey: 'password.type',
   actionKey: UPDATE_ACTION,
};

export const SDR_REPORT_STATUS_LOOKUP = gql`
   query getSDRReport($reportDate: DateOnly!) {
      sdrLookup: sdrReportStatus_ReportLookup(reportDate: $reportDate) {
         ...sdrLookupInfo
      }
   }
   ${SDR_LOOKUP_FRAGMENT}
`;

export const SDR_MONTH_REPORT = gql`
   query getSDRReport($reportMonthDate: DateOnly!) {
      sdrMonthReport: sdrReportStatus_MonthReport(reportMonthDate: $reportMonthDate) {
         ...sdrReportStatusInfo
      }
   }
   ${SDR_REPORT_STATUS_FRAGMENT}
`;

export const SDR_REPORT_STATUS_UPDATE = {
   mutation: gql`
      mutation sdrReportStatusCreateUpdate($id: UUID!, $status: String) {
         sdrReportStatus: sdrReportStatus_Update(sdrReportStatusId: $id, sdrReportStatus: {status: $status}) {
            ...sdrReportStatusInfo
         }
      }
      ${SDR_REPORT_STATUS_FRAGMENT}
   `,
   typeKey: CREATE_UPDATE_ACTION,
   actionKey: 'sdrReportStatus.type',
   // updateQueries: (variables) => {
   //    return [{query: SDR_MONTH_REPORT}];
   // },
};

export const CASH_ACTIVITY_ACTIVE_CLIENTS = gql`
   query getCashActivity_ActiveClients($dateFrom: DateOnly, $dateTo: DateOnly) {
      clients: cashActivity_ActiveClients(dateFrom: $dateFrom, dateTo: $dateTo) {
         ...clientInfo
      }
   }
   ${CLIENT_FRAGMENT}
`;

export const GET_FEP_ADDRESS = gql`
   query getFEPAddress {
      address: getFEPAddress {
         ...fepAddressInfo
      }
   }
   ${FEP_ADDRESS_FRAGMENT}
`;
