// Dates displayed to the user or to match the DB format.

export const DATE_FORMAT_KEYBOARD = 'MM/DD/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const DEFAULT_DATE_FORMAT = 'M/D/YYYY';
export const SHORT_DATE_FORMAT = 'MM/DD/YY';
// export const MONTH_ONLY_FORMAT = 'MMM';
// export const YEAR_FORMAT = 'YYYY';
// export const DATE_TIME_FORMAT = 'M/D/YYYY hh:mm a';
// eslint-disable-next-line
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_DB_FORMAT = 'YYYY-MM-DD';
// export const CURRENCY_FORMAT = '$#,###,###,##0.';
// export const CURRENCY_FULL_FORMAT = '$#,###,###,##0.00';
export const POSITION_DATE = 'MMM-YYYY';

export const PRIMARY_COLOR = '#1B74CE';
export const ERROR_COLOR = '#AA0B06';
export const WARNING_COLOR = '#F5CD19';
export const SUCCESS_COLOR = '#5C9E52';

export const colors = [
   '#EDBA2E',
   '#336699',
   // '#4386C3',
   '#A3C7E4',
   '#007373',
   '#000000',
   '#2170B8',
   '#DC2A30CB',
   '#6A8E9A',
   '#008080',
   '#B521B8',
   // '#FFCA42',
   '#CFB047',
   '#939393',
   '#3B8AD2',
   '#707070',
];

// export const COLLAPSE_TIMEOUT = 500;
export const UNDO_DURATION = 4000;

export const ENDPOINT = !process.env.REACT_APP_ENDPOINT
   ? '/api/graphql/'
   : `http://${process.env.REACT_APP_ENDPOINT}/api/graphql/`;
console.log('Endpoint = ', ENDPOINT);
console.log('Version = ', process.env.REACT_APP_VERSION);
export const SERVER_ENDPOINT = !process.env.REACT_APP_ENDPOINT ? '' : `http://${process.env.REACT_APP_ENDPOINT}`;

export const FUTURE_CODE = 'f';
export const OPTIONS_CODE = 'o';

export const APPBAR_HEIGHT = 0;
export const APPBAR_SMALL_HEIGHT = 0;
export const DRAWER_WIDTH = 220;
// export const EDIT_DRAWER_WIDTH = 300;
export const ADMIN_DRAWER = DRAWER_WIDTH;
export const LIST_WIDTH_DEFAULT = 360;

// Deal Type Order
export const DEAL_TYPES_PAIRS = [
   ['Ratable', 'Non Ratable'],
   ['Look Alike', 'Look Alike Option'],
   ['Asian Option', 'Ratable Asian Option'],
];

// Report Frequency
export const DAILY_REPORT = 'daily';
export const MONTHLY_REPORT = 'monthly';

// export const BUCKET_NAME = 'flashpoint-test-temp-public';
// eslint-disable-next-line
export const FACILITY_LOCATION_PATH = `https://nominatim.openstreetmap.org/search?format=json&city={city}&postalcode={zipCode}&state={state}`;
// eslint-disable-next-line
export const FACILITY_LOCATION_GEOCOD_PATH = `https://api.geocod.io/v1.7/geocode?q={addressLineOne}, {city}, KS {zipCode}&limit=1&api_key=b912ca591d2f82a5a98d5a2f0a8a255dfa51200`;

export const LOGO = '/images/logo-square.jpeg';
export const LOGO_SMALL = '/images/Logo.png';
export const LOGO_LARGE = '/images/logo-large.png';
export const BACKGROUND_FADED = '/images/background faded-2.png';
export const SHAKE = '/images/shake.jpeg';
// export const SHAKE = '/images/shakePred.jpeg';
export const STATEMENT_IMAGE = '/images/statement-icon-20.jpg';
export const SETTLEMENT_IMAGE = '/images/statement-icon-20.jpg';
export const REPORT_IMAGE = '/images/report.png';
export const EMPTY_BAR_CHART_IMAGE = '/images/empty-bar-chart.png';
export const HERO_IMAGE = '/images/flashpoint-hero-tanks.jpg';

export const NEW_STATUS = 'new';
export const UPDATED_STATUS = 'updated';
// export const DELETED_STATUS = 'deleted';

export const SCROLL_DELTA = 50;
export const HORIZONTAL_DIRECTION = 'row';
export const VERTICAL_DIRECTION = 'column';

// The default page size for lists.
export const DEFAULT_PAGE_SIZE = 100;
// 'All' page size is set to a finite value, which should exceed all table sizes.
export const ALL_PAGE_SIZE = 64000;

export const DEFAULT_YEARS = 5;

// export const DEFAULT_YEARS_CLIENT = 10;

export const DEFAULT_PATH = '/';
export const NEW_PATH = 'new';
export const LANDING_PATH = 'landing';
export const ADMIN_PATH = 'admin';
export const COMMODITIES_PATH = 'commodity';
export const TRADER_PATH = 'trader';
export const LOCATION_PATH = 'location';
export const LOCATION_ITEM_PATH = 'locationId';
export const LOCATION_FULL_ITEM_PATH = `${ADMIN_PATH}/${LOCATION_PATH}/:${LOCATION_ITEM_PATH}`;
export const COMMODITY_PATH = 'commodity';
export const COMMODITY_ITEM_PATH = 'commodityId';
export const COMMODITY_FULL_PATH = `${ADMIN_PATH}/${COMMODITY_PATH}`;
export const COMMODITY_FULL_ITEM_PATH = `${COMMODITY_FULL_PATH}/:${COMMODITY_ITEM_PATH}`;
export const USER_PATH = 'user';
export const USER_ITEM_PATH = 'userId';
export const SETTINGS_PATH = 'settings';
export const DEAL_PASSWORD_PATH = 'dealPassword';
export const CLIENT_ITEM_PATH = 'clientId';
export const CLIENT_LANDING_ADMIN_FULL_PATH = `${ADMIN_PATH}/${LANDING_PATH}`;
export const TWO_FACTOR_AUTHENTICATION_PATH = '2fa';
export const CLIENT_LANDING_ADMIN_FULL_PATH_2 = `${ADMIN_PATH}/${LANDING_PATH}/:${CLIENT_ITEM_PATH}/:reportType`;
export const DEAL_PATH = 'deal';
export const DEAL_FULL_PATH = `${ADMIN_PATH}/${DEAL_PATH}`;
export const DEAL_TABLE_PATH = 'table';
export const DEAL_DATA_PATH = 'data';
export const DEAL_LIST_PATH = 'list';
export const DEAL_FULL_LIST_PATH = `${DEAL_FULL_PATH}/${DEAL_LIST_PATH}`;
export const DEAL_PART_LIST_PATH = `${DEAL_PATH}/${DEAL_LIST_PATH}`;
export const DEAL_PART_TABLE_PATH = `${DEAL_PATH}/${DEAL_TABLE_PATH}`;
export const DEAL_PART_DATA_PATH = `${DEAL_PATH}/${DEAL_DATA_PATH}`;
export const DEAL_PART_PASSWORD_PATH = `${DEAL_PATH}/${DEAL_PASSWORD_PATH}`;
export const CLIENT_PATH = 'client';
export const CONTACT_PATH = 'contact';
export const SOCIAL_PATH = 'social';
export const ONBOARD_PATH = 'onboard';
export const INFO_PATH = 'info';
export const CLIENT_FULL_PATH = `${ADMIN_PATH}/${CLIENT_PATH}`;
// eslint-disable-next-line
export const SIGN_OUT_PATH = 'signout';
export const CLIENT_EDIT_TAB_PATH = `${CLIENT_FULL_PATH}/:clientId/:tab`;
export const CLIENT_EDIT_TAB_ID_PATH = `${CLIENT_FULL_PATH}/:clientId/:tab/:id`;

export const REPORT_PATH = 'report';
export const REPORT_ITEM_PATH = ':reportId';
export const POSITION_REPORT_PATH = 'position';
export const CASH_ACTIVITY_PATH = 'cash';
export const POSITION_REPORT_PART_PATH = `${REPORT_PATH}/${POSITION_REPORT_PATH}`;
export const CASH_ACTIVITY_FULL_PATH = `${REPORT_PATH}/${CASH_ACTIVITY_PATH}`;
export const NEW_CASH = 'new';

export const STATEMENT_PATH = 'statement';
export const STATEMENT_PART_PATH = `${REPORT_PATH}/${STATEMENT_PATH}`;
export const STATEMENT_FULL_ITEM_PATH = `${ADMIN_PATH}/${REPORT_PATH}/${STATEMENT_PATH}/:${CLIENT_ITEM_PATH}`;

export const SETTLEMENT_PATH = 'settlement';
export const SETTLEMENT_PART_PATH = `${REPORT_PATH}/${SETTLEMENT_PATH}`;
export const SETTLEMENT_FULL_ITEM_PATH = `${ADMIN_PATH}/${REPORT_PATH}/${SETTLEMENT_PATH}/:${CLIENT_ITEM_PATH}`;
export const COMMODITY_MONTHLY_PATH = 'monthly';
export const SETTLEMENT_COMMODITY_MONTHLY_PATH = `${SETTLEMENT_PART_PATH}/${COMMODITY_MONTHLY_PATH}`;
export const SETTLEMENT_COMMODITY_MONTHLY_FULL_PATH = `${ADMIN_PATH}/${REPORT_PATH}/${SETTLEMENT_PATH}/${COMMODITY_MONTHLY_PATH}/:${COMMODITY_ITEM_PATH}`;
export const SDR_PATH = 'sdr';
export const SDR_PART_PATH = `${REPORT_PATH}/${SDR_PATH}`;
export const SDR_FULL_PATH = `${ADMIN_PATH}/${SDR_PART_PATH}`;

// export const BANK_TYPE = ' bank';
// export const CLIENT_TYPE = 'client;';
export const CONTACT_TYPE = 'contact';
export const DEAL_TYPE = 'deal';
export const PAPERWORK_TYPE = 'paperwork';
export const COMMODITY_TYPE = 'commodity';
// export const MEASUREMENT_TYPE = 'unit';
export const PHONE_TYPE = 'phone';
export const EXCHANGE_TYPE = 'exchange';
export const SOCIAL_TYPE = 'social';
export const PHYSICAL_DELIVERY_METHOD = 'physDeliveryMethod';
export const UNDERLYING_TYPE = 'underlying';
export const PS_CODE_TYPE = 'psCode';
export const ADMIN_GROUP = 'Admin';

// upload file
export const FILE_BUCKET = process.env.REACT_APP_POOL === 'production' ? 'flashpoint-prod' : 'flashpoint-test';

export const CASH_ITEM_PATH = 'cashId';

export const NOMFA = 'NOMFA';
export const SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA';
