import {clone} from 'lodash';
import {toNumber} from 'lodash';
import {delay} from 'lodash';
import {isString} from 'lodash';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObjectLike from 'lodash/isObjectLike';
import pick from 'lodash/pick';
import {useState, useEffect, useCallback} from 'react';
import {v4 as uuid} from 'uuid';
import {hasValue as hasValueUtil} from '../../utils/Utils';
import {editChange} from '../../utils/Utils';

const REQUIRED_DEFAULTS = ['id'];

export default function useEditData(defaultValuesProp, requiredEditValues = REQUIRED_DEFAULTS, onChange) {
   const [isChanged, setIsChanged] = useState(false);
   const [defaultValues, setDefaultValuesProp] = useState(defaultValuesProp || {});
   const required = !isArray(requiredEditValues) ? {id: uuid(), ...requiredEditValues} : {id: uuid()};
   const [editValues, setEditValuesLocal] = useState(required);
   const [currentValues, setCurrentValues] = useState({...(defaultValuesProp || {}), ...required});

   useEffect(() => {
      if (defaultValues) {
         let requiredObject;

         if (isObjectLike(requiredEditValues)) {
            if (isArray(requiredEditValues)) {
               requiredObject = pick(defaultValues, ['id', ...requiredEditValues]);
            } else {
               requiredObject = requiredEditValues;
            }
         } else {
            requiredObject = {};
         }
         setEditValues({...editValues, ...requiredObject});
      }
      //editValues and requiredEditValues cause constant changes.
      // eslint-disable-next-line
   }, [defaultValues]);

   const setDefaultValues = useCallback((defaultValuesProp = {}) => {
      if (defaultValuesProp?.id) {
         setDefaultValuesProp(defaultValuesProp);
      } else {
         setDefaultValuesProp({id: uuid(), ...defaultValuesProp});
      }
   }, []);

   const setEditValues = useCallback(
      (values) => {
         setEditValuesLocal(values);
         setCurrentValues({...defaultValues, ...values});
      },
      [defaultValues],
   );

   const handleChangeWithName =
      (name, valueKey = 'id') =>
      (event, value, reason) => {
         if (reason === 'selectOption') {
            if (isString(value)) {
               handleChange(event, value, reason, {[name]: value}, name);
            } else {
               handleChange(event, value[valueKey], reason, undefined, name);
            }
         } else {
            handleChange(event, value, reason, undefined, name);
         }
      };

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    * @param [value] The value if the component is an Autocomplete
    * @param [reason] The reason of the value change if Autocomplete
    * @param [newValue] The value from the component.
    * @param [name] the name of the component.
    */
   const handleChange = (event, value, reason, newValue, name) => {
      let useValue = newValue;

      let newEditValues;

      if (newValue) {
         newEditValues = {...editValues, ...newValue};
      } else {
         useValue = editChange(event, value, reason, true, newValue, name);
         newEditValues = {...editValues, ...useValue};
      }
      setEditValuesLocal((editValues) => ({...editValues, ...useValue}));
      setCurrentValues((currentValues) => ({...currentValues, ...useValue}));

      if (onChange) {
         let requiredObject;

         if (isArray(requiredEditValues)) {
            requiredObject = pick(defaultValues, ['id', ...requiredEditValues]);
         } else {
            requiredObject = {...defaultValues};
         }
         //1) Changed now and required, 2) All changed, 3) All changed and defaults for unchanged.
         onChange?.({...requiredObject, ...useValue}, newEditValues, {...defaultValues, ...newEditValues});
      }

      if (reason !== 'reset') {
         setIsChanged(true);
      }
      return useValue;
   };

   const handleDateChange = (value, name) => {
      handleChange(undefined, undefined, undefined, {[name]: value});
   };

   const handleArrayChange = (event) => {
      const index = toNumber(get(event, 'target.dataset.index'));
      const {componentName, newValue} = editChange(event, undefined, undefined, false);

      const editValuesLocal = clone(editValues);

      if (editValuesLocal[componentName]?.length === undefined) {
         editValuesLocal[componentName] = clone(defaultValues?.[componentName] || []);
      }

      editValuesLocal[componentName][index] = newValue;

      setEditValues(editValuesLocal);
      setIsChanged(true);
   };

   const resetValues = useCallback(
      (defaultValuesLocal = {}) => {
         let requiredObject;
         const useDefaultValues = {id: uuid(), ...defaultValuesLocal};

         if (isArray(requiredEditValues)) {
            requiredObject = pick(useDefaultValues, ['id', ...requiredEditValues]);
         } else {
            requiredObject = requiredEditValues;
         }
         setEditValuesLocal({...requiredObject});
         setDefaultValues(useDefaultValues);
         setCurrentValues({...requiredObject, ...useDefaultValues});

         setIsChanged(false);
      },
      [requiredEditValues, setDefaultValues],
   );

   const handleSelectChange = (value, name) => {
      setEditValuesLocal((editValues) => ({...editValues, [name]: value}));
      setCurrentValues((currentValues) => ({...currentValues, [name]: value}));
      setIsChanged(true);
   };

   /**
    * Get the current value for the named property. If the value has been edited, it will return the edited value even
    * if it is null, and it will return the default value if not edited. If there is no default value, the default
    * value from the parameter is used.
    *
    * @Param path The path to the property
    * @Param defaultValue The default value to use if there isn't an edit or default value already.
    *
    * @type {function(*, *=): *}
    */
   const getValue = useCallback(
      (path, defaultValue = '') => {
         const editValue = get(editValues, path);
         const originalDefaultValue = get(defaultValues, path);
         return editValue !== undefined
            ? editValue
            : originalDefaultValue || originalDefaultValue === 0
            ? originalDefaultValue
            : defaultValue;
      },
      [editValues, defaultValues, currentValues],
   );

   /**
    * Get the current value for the named property. If the value has been edited, it will return the edited value even
    * if it is null, and it will return the default value if not edited. If there is no default value, the default
    * value from the parameter is used.
    *
    * @Param path The path to the property
    * @Param defaultValue The default value to use if there isn't an edit or default value already.
    *
    * @type {function(*, *=): *}
    */
   const setValue = useCallback(
      (path, value, isChanged = false) => {
         if (editValues?.[path] !== value) {
            const newEditValues = {...editValues, [path]: value};

            setEditValuesLocal((editValues) => {
               return {...editValues, [path]: value};
            });
            setCurrentValues((currentValues) => {
               return {...currentValues, [path]: value};
            });

            setIsChanged(isChanged);

            if (onChange && isChanged) {
               let requiredObject;

               if (isArray(requiredEditValues)) {
                  requiredObject = pick(defaultValues, ['id', ...requiredEditValues]);
               } else {
                  requiredObject = {...defaultValues};
               }
               //1) Changed now and required, 2) All changed, 3) All changed and defaults for unchanged.
               onChange?.({...requiredObject, ...newEditValues}, newEditValues, {...defaultValues, ...newEditValues});
            }
         }
      },
      [editValues, defaultValues, onChange, requiredEditValues],
   );

   const resetValue = useCallback(
      (path) => {
         setValue(path, get(defaultValues, path));
      },
      [defaultValues, setValue],
   );

   /**
    * Indicates if there is a value set for the property. If the default value is deleted, false will be returned even
    * though there is a defaultValue.
    *
    * @Param name The name of the property
    * @type {function(*=, *=): boolean}
    */
   const hasValue = useCallback(
      (name) => {
         return hasValueUtil(getValue(name));
      },
      [getValue],
   );

   const handleInputChange = (name) => (event, value, reason) => {
      if (reason === 'reset') {
         delay(() => {
            setValue(name, undefined);
         });
      } else {
         handleChange(event, value, reason, undefined, name);
      }
   };

   return [
      editValues,
      handleChange,
      {
         handleSelectChange,
         handleChangeWithName,
         handleDateChange,
         isChanged,
         setIsChanged,
         setEditValues,
         defaultValues,
         setDefaultValues,
         currentValues,
         resetValues,
         resetValue,
         getValue,
         setValue,
         hasValue,
         handleInputChange,
         handleArrayChange,
      },
   ];
}
